import React, { useEffect, useState } from "react";
import { message, Table, Tag } from 'antd';
import { getQuery } from "../../../services/apiService";
import { convertDecimal128 } from "../../../utils/convertNumbers";

const BankAccountsTab = ({ activeTab, userID }) => {
    const [bankAccounts, setBankAccounts] = useState([]);
    console.log('User ID',userID)

    useEffect(() => {
        const fetchBalances  = async () => {
            try{
                const response = await getQuery("/api/v1/user-banking/get-banking", { userID });
                if(response.status === 200 && response.data.status && response.data.data){
                    setBankAccounts(response.data.data);
                    return
                }

                message.error('Balance data for this user not found.');
            }catch(error){
                message.error('Balance data for this user not found.');
            }
        }

        fetchBalances();
    }, [userID]);

    const columns = [
		{
			title: 'Bank Name',
			dataIndex: 'bankName',
			key: 'bankName',
		},
		{
			title: 'Acount Number',
			dataIndex: 'accNumber',
			key: 'accNumber',
		},
		{
			title: 'Branch Code',
			dataIndex: 'branchCode',
			key: 'available',
		},
	];
	return(
		<>
			<Table dataSource={bankAccounts} columns={columns} />
		</>
	)
}

export default BankAccountsTab;