// src/Modals/News/AddNewsModal.jsx

import React from "react";
import { Modal, Form, Input, Button, Select, DatePicker } from "antd";
import axios from "axios";

const { Option } = Select;

const AddNewsModal = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        // Process tags to ensure it's an array
        const processedValues = {
          ...values,
          tags: values.tags
            ? values.tags.split(",").map((tag) => tag.trim())
            : [],
          // Convert DatePicker value to ISO string
          date: values.date ? values.date.toISOString() : null,
        };
        try {
          // Use relative URL instead of absolute URL
          await axios.post("/api/v1/news/create", processedValues);
          form.resetFields();
          onSubmit();
        } catch (error) {
          // Log detailed error information
          console.error(
            "Error adding news:",
            error.response?.data || error.message
          );
          // Optionally, display an error message to the user
          Modal.error({
            title: "Error",
            content:
              error.response?.data?.message ||
              "An unexpected error occurred while adding the news.",
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      visible={visible}
      title="Add News"
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Add
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="add_news_form">
        {/* Essential Field: Name */}
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please input the news name!" }]}
        >
          <Input />
        </Form.Item>

        {/* Essential Field: Link */}
        <Form.Item
          name="link"
          label="Link"
          rules={[
            { required: true, message: "Please input the news link!" },
            { type: "url", message: "Please enter a valid URL!" },
          ]}
        >
          <Input />
        </Form.Item>

        {/* Essential Field: Date */}
        <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select the date!" }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>

        {/* Optional Field: Description */}
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>

        {/* Optional Field: Source */}
        <Form.Item name="source" label="Source">
          <Input />
        </Form.Item>

        {/* Optional Field: Category */}
        <Form.Item name="category" label="Category">
          <Select placeholder="Select a category" allowClear>
            <Option value="Technology">Technology</Option>
            <Option value="Business">Business</Option>
            <Option value="Health">Health</Option>
            <Option value="Sports">Sports</Option>
            <Option value="Politics">Politics</Option>
            <Option value="Entertainment">Entertainment</Option>
            <Option value="Science">Science</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Form.Item>

        {/* Optional Field: Tags */}
        <Form.Item name="tags" label="Tags">
          <Input placeholder="Enter tags separated by commas" />
        </Form.Item>

        {/* Optional Field: Author */}
        <Form.Item name="author" label="Author">
          <Input />
        </Form.Item>

        {/* Optional Field: Status */}
        <Form.Item name="status" label="Status">
          <Select placeholder="Select status" allowClear>
            <Option value="Published">Published</Option>
            <Option value="Draft">Draft</Option>
            <Option value="Archived">Archived</Option>
          </Select>
        </Form.Item>

        {/* Optional Field: Image URL */}
        <Form.Item
          name="imageUrl"
          label="Image URL"
          rules={[{ type: "url", message: "Please enter a valid image URL!" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNewsModal;
