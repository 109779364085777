import React, { useState } from "react";
import {
  Eye,
  EyeOff,
  Mail,
  Lock,
  Facebook,
  Twitter,
  Github,
} from "lucide-react";
import logo from "../../assets/icons/logo.png"; // Adjust the path based on your project structure

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div className="min-h-screen flex flex-col lg:flex-row">
      {/* Left Section */}
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2 px-6 py-12 bg-white">
        {/* Logo */}
        <div className="mb-8">
          <img src={logo} alt="Company Logo" className="h-12 w-auto" />
        </div>

        <h1 className="text-3xl font-extrabold text-gray-800 mb-4">
          Welcome Back
        </h1>
        <p className="text-gray-600 text-center mb-6">
          Start trading in seconds with our secure platform.
        </p>

        {/* Social Login */}
        <div className="flex flex-col space-y-3 mb-6 w-full max-w-xs">
          <button className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
            <Facebook size={20} className="mr-2" />
            Sign in with Facebook
          </button>
          <button className="flex items-center justify-center px-4 py-2 bg-blue-400 text-white rounded-lg hover:bg-blue-500">
            <Twitter size={20} className="mr-2" />
            Sign in with Twitter
          </button>
          <button className="flex items-center justify-center px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-900">
            <Github size={20} className="mr-2" />
            Sign in with GitHub
          </button>
        </div>

        {/* Or Divider */}
        <div className="flex items-center w-full max-w-xs mb-6">
          <hr className="flex-grow border-gray-300" />
          <span className="mx-3 text-gray-500">or</span>
          <hr className="flex-grow border-gray-300" />
        </div>

        {/* Login Form */}
        <form className="w-full max-w-xs">
          {/* Email Input */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 font-semibold mb-2"
            >
              Email
            </label>
            <div className="relative">
              <Mail className="absolute left-3 top-3 text-gray-400" size={20} />
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
              />
            </div>
          </div>

          {/* Password Input */}
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-gray-700 font-semibold mb-2"
            >
              Password
            </label>
            <div className="relative">
              <Lock className="absolute left-3 top-3 text-gray-400" size={20} />
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                placeholder="Enter your password"
                className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none"
              />
              <div
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-500 hover:text-gray-700"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
              </div>
            </div>
          </div>

          {/* Remember Me and Forgot Password */}
          <div className="flex items-center justify-between mb-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-purple-600"
              />
              <span className="ml-2 text-gray-600 text-sm">Remember me</span>
            </label>
            <a href="#" className="text-sm text-purple-600 hover:underline">
              Forgot password?
            </a>
          </div>

          {/* Sign In Button */}
          <button
            type="submit"
            className="w-full bg-pink-600 hover:bg-purple-700 text-white py-2 rounded-2xl text-lg font-semibold shadow-md transition-all"
          >
            Sign In
          </button>
        </form>

        {/* Sign Up Link */}
        <p className="mt-6 text-gray-600 text-sm">
          Don't have an account?{" "}
          <a href="#" className="text-purple-600 font-medium hover:underline">
            Sign up here
          </a>
        </p>
      </div>

      {/* Right Section */}
      <div className="hidden lg:flex w-full lg:w-1/2 items-center justify-center bg-gray-100 rounded-3xl">
        <div className="max-w-md text-center p-8">
          <h2 className="text-gray-600 text-3xl font-bold mb-4">
            Trade with Confidence
          </h2>
          <p className="text-purple-500 mb-6">
            Experience the best trading platform with top-notch security and
            user-friendly interface.
          </p>
          <img
            src="https://img.freepik.com/free-vector/sign-concept-illustration_114360-29057.jpg?ga=GA1.1.305887151.1724215052&semt=ais_hybrid"
            alt="Trading Illustration"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
