import { useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all users---------
export const useGetAllUsers = () => {
  return useApiQuery({
    key: ["clients"],
    endPoint: `/api/v1/clients/index`,
    method: "GET",
  });
};
