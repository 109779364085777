import React, { useState } from "react";
import { Button, Modal, Tabs } from "antd";
import DetailView from "../../components/DetailView";
import { convertDecimal128 } from "../../utils/convertNumbers";
import  { BlockOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CurrencyPairsTab from "./Tabs/CurrencyPairsTab";
import AssetChainsTab from "./Tabs/AssetChainsTab";
import DetailsTab from "./Tabs/DetailsTab";

const { TabPane } = Tabs;

const View = ({ assetId }) => {

  const [visible, setVisible] = useState(false);
  

  const toggleView = () => {
    setVisible(!visible);
  };
  
  if (!visible) {
    return (
      <Button
          type="text"
          icon={<EyeOutlined />}
          style={{
              padding: 0, // Removes padding for a cleaner look
              display: "flex", // Ensures proper alignment
              alignItems: "center",
              justifyContent: "center",
          }}
          onClick={toggleView} // Properly placed onClick handler
      />
    );
  }

  return (
    <Modal
      title="View Asset"
      open={visible}
      onCancel={toggleView}
      footer={null}
      width={1200}
      style={{ maxHeight: '80vh', overflowY: 'auto', backgroundColor: '#FFFFFF' }}
    >
      <Tabs defaultActiveKey="1" type="card">
        {/* Tab 1: Asset Details */}
        <TabPane 
          tab={
            <span>
              <InfoCircleOutlined style={{ marginRight: 8 }} />
              Asset Details
            </span>
          } 
          key="1"
        >
          {/*<DetailView data={assetDetails} className="w-full" />*/}
          {<DetailsTab assetId={assetId} />}
        </TabPane>

        {/* Tab 2: Tab for currency pair */}
        <TabPane tab="Pairs" key="2">
          <div>
            {<CurrencyPairsTab assetId={assetId} />}
          </div>
        </TabPane>

        {/* Tab 3: Tab for asset chains */}

        <TabPane 
          tab={
            <span>
              <BlockOutlined style={{ marginRight: 8 }} />
              Chains
            </span>
          } 
          key="3">
          <div>
            {<AssetChainsTab assetId={assetId}/>}
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default View;
