// src/Modals/News/DeleteModal.jsx

import React from 'react';
import { Modal, Button } from 'antd';

const DeleteModal = ({ visible, onCancel, newsItem, handleDelete }) => {
    const newsId = newsItem?._id;

    // Log the newsId to verify it's a string
    console.log("DeleteModal: newsId =", newsId);

    const onDelete = () => {
        if (newsId && typeof newsId === 'string') {
            handleDelete(newsId);
        } else {
            console.error('Delete operation failed: Invalid or missing news ID.', newsId);
            Modal.error({
                title: 'Error',
                content: 'Unable to delete the news item due to an invalid or missing ID.',
            });
        }
    };

    return (
        <Modal
            title="Delete News"
            open={visible} // Use 'visible' for Ant Design v4; replace with 'open' for v5
            onCancel={onCancel}
            footer={null}
            destroyOnClose
        >
            <p>Are you sure you want to delete "{newsItem?.name}"?</p>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type="primary" danger onClick={onDelete}>
                    Delete
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteModal;
