import { Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { convertDecimal128 } from "../../../utils/convertNumbers";
import { getQuery } from "../../../services/apiService";

const AssetChainsTab = ({assetId}) => {
    const [chains, setChains] = useState([]);
    console.log(assetId);

    useEffect(() => {
        const fetchChains = async () => {
            console.log(assetId);
            const response = await getQuery('/api/v1/trading-assets/get-chains', {assetId});
            const data = response.data.data || []; // Ensure data exists
            console.log(response);
            setChains(data);
        }

        fetchChains();
    }, []);

	const columns = [
		{
			title: 'Chain',
			dataIndex: 'chain',
			key: 'chain',
		},
		{
			title: 'Fireblocks Test',
			dataIndex: 'fireblocksTest',
			key: 'sellFee',
		},
		{
			title: 'Fireblocks Live',
			dataIndex: 'fireblocksLive',
			key: 'sellFee',
		},
		/*{
			title: 'Rate API',
			dataIndex: 'rateApi',
			key: 'rateApi',
			render: (rateApi) => (
				<Tag color={rateApi === 'valr' ? 'blue' : 'green'}>
					{rateApi.toUpperCase()}
				</Tag>
			),
		},
		{
			title: 'Base Currency',
			dataIndex: ["baseCurrency", "asset"],
			key: 'baseCurrency',
			render: (status) => (
				<Tag color={'blue'}>
				{status}
				</Tag>
			),
		},
		{
			title: 'Quote Currency',
			dataIndex: ["quoteCurrency", "asset"],
			key: 'quoteCurrency',
			render: (quoteCurrency) => (
				<Tag color={'green'}>
				{quoteCurrency}
				</Tag>
			),
		},
		{
			title: 'Status',
			dataIndex: 'enabled',
			key: 'status',
			render: (enabled) => (
				<Tag color={enabled === 'enabled' ? 'green' : 'red'}>
					{enabled}
				</Tag>
			),
		},*/
	];
	return(
		<>
			<Table dataSource={chains} columns={columns} />
		</>
	)
}

export default AssetChainsTab;