// AddWithdrawalModal.js

import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useState } from 'react';
import AdminLayout from '../../../layouts/AdminLayout';
import { useGetAllAssets, useGetAllChains } from '../../../services/assetService';
import { useGetAllUsers } from '../../../services/userService';
import { useAddUserCryptoWithdrawalService } from '../../../services/withdrawalCryptoService';

const { Option } = Select;
const { TextArea } = Input;

const Add = ({ visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const { addUserCryptoWithdrawal } = useAddUserCryptoWithdrawalService()
    const { data: users, error: errorUser, isLoading: loadingUser } = useGetAllUsers();
    const { data: assets, error: errorAsset, isLoading: loadingAsset } = useGetAllAssets("type=Crypto"); // Crypto Fiat
    const { data: chains } = useGetAllChains(assetId);

    const handleAdd = async () => {
        setLoading(true)

        const values = await form.validateFields();

        const payload = {
            userId: values.user,
            cryptoAsset: values.asset,
            walletAddress: values.walletAddress,
            chain: values.chain,
            amount: values.amount,
            fee: values.fees,
            txid: values.txid,
            notes: values.notes,
            transactionType: "send",
        }

        addUserCryptoWithdrawal(
            payload,
            () => {
                onCancel();
                onSubmit();
                setLoading(false)
            },
            () => setLoading(false)
        )
    };

    if (errorUser && errorAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-red-500">Error Couldn't find assets or users </div>
                </div>
            </AdminLayout>
        );
    }

    if (loadingUser && loadingAsset) {
        return (
            <AdminLayout>
                <div className="mx-auto p-6 bg-white">
                    <div className="text-blue-500">Loading...</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <Modal
            visible={visible}
            title="New Withdrawal Request"
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button
                    key="save"
                    type="primary"
                    onClick={handleAdd}
                    loading={loading}
                >
                    Save
                </Button>,
            ]}>
            <Form form={form} layout="vertical">
                <Form.Item
                    name="user"
                    label="User"
                    rules={[{ required: true, message: 'Please select a user' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a user"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }>
                        {users?.results?.map(user => (
                            <Option key={user?._id} value={user?._id}>
                                {`${user?.email} - ${user?.username}`}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="asset"
                    label="Asset"
                    rules={[{ required: true, message: 'Please select an asset' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select an asset"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }

                        onChange={(value) => {
                            const selectedAsset = assets?.find(asset => asset.asset === value); setAssetId(selectedAsset?._id);
                        }}
                    >
                        {assets?.map(asset => {
                            return (
                                <Option key={asset?._id} value={asset?.asset}>
                                    {asset?.assetName}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                {/* Get all chains */}
                <Form.Item
                    name="chain"
                    label="Chain"
                    rules={[{ required: true, message: 'Please select a chain' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select a chain"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {chains?.map(chain => {
                            return (
                                <Option key={chain?._id} value={chain?._id}>
                                    {chain?.chain}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="walletAddress"
                    label="Wallet Address"
                    rules={[
                        { required: true, message: 'Please enter the wallet address' },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Enter amount"
                    />
                </Form.Item>


                <Form.Item
                    name="amount"
                    label="Amount"
                    rules={[
                        { required: true, message: 'Please enter the amount' },
                        { type: 'number', min: 0, message: 'Amount must be positive' },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        step="0.00000001"
                        placeholder="Enter amount"
                    />
                </Form.Item>

                <Form.Item
                    name="fees"
                    label="Fees"
                    rules={[
                        { required: true, message: 'Please enter the fees' },
                        { type: 'number', min: 0, message: 'Fees must be positive' },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        step="0.00000001"
                        placeholder="Enter fees"
                    />
                </Form.Item>

                <Form.Item
                    name="txid"
                    label="Transaction ID"
                >
                    <Input placeholder="Enter transaction ID" />
                </Form.Item>

                <Form.Item
                    name="notes"
                    label="Notes"
                >
                    <TextArea rows={4} placeholder="Enter notes" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Add;
