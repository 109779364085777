import React, { useState } from "react";
import { Modal, Button, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteResource } from "../../services/apiService";

const Delete = ({ userId, onDeleteSuccess }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggleModal = () => {
        setVisible(!visible);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            //const response = await deleteUser(`/api/v1/clients/${userId}`);
			console.log('Deleting...');
			const response = await deleteResource('/api/v1/clients/delete', {clientId: userId});
            if (response.status === 200) {
                message.success("Client deleted successfully.");
                onDeleteSuccess(Math.random() > 0.5); // Callback to refresh parent component or handle success
                toggleModal();
            } else {
                throw new Error("Failed to delete client.");
            }
        } catch (error) {
            message.error(error.message || "Error deleting client.");
        } finally {
            setLoading(false);
        }
    };

	if (!visible) {
		return (
		  <Button
			  type="text"
			  icon={<DeleteOutlined />}
			  style={{
				  padding: 0, // Removes padding for a cleaner look
				  display: "flex", // Ensures proper alignment
				  alignItems: "center",
				  justifyContent: "center",
			  }}
			  onClick={toggleModal} // Properly placed onClick handler
		  />
		);
	}

    return (
        <>
            <Button type="danger" onClick={toggleModal}>
                Delete Client
            </Button>

            <Modal
                title="Delete Client"
                open={visible}
                onCancel={toggleModal}
                footer={null}
                destroyOnClose
                style={{ maxWidth: "600px" }}
            >
                <div>
                    <p>Are you sure you want to delete this client?</p>
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                        <Button onClick={toggleModal}>Cancel</Button>
                        <Button type="primary" danger loading={loading} onClick={handleDelete}>
                            Delete
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Delete;
