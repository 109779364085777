import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select, Switch } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const FAQForm = ({ onSubmit, onPrevious, previousData }) => {
  const [form] = Form.useForm();
  const [faqs, setFaqs] = useState([{ key: Date.now() }]); // Initialize with one row

  const handleAddRow = () => {
    setFaqs([...faqs, { key: Date.now() }]);
  };

  const handleRemoveRow = (key) => {
    setFaqs(faqs.filter((faq) => faq.key !== key));
  };

  const onFinish = (values) => {
    const faqsData = values.faqs || [];
    console.log("FAQ Form Data:", { faqs: faqsData, ...previousData });
    onSubmit({ faqs: faqsData, ...previousData });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="p-4"
      initialValues={{ faqs: faqs.map(() => ({})) }}
    >
      <h2 className="text-xl font-bold mb-4">FAQ Form</h2>

      {faqs.map((faq, index) => (
        <Row gutter={16} key={faq.key} className="mb-2" align="middle">
          {/* Product Field */}
          <Col span={5}>
            <Form.Item
              label="Product"
              name={["faqs", index, "productId"]}
              rules={[{ required: false, message: "Product is required" }]}
              initialValue={previousData.name}
            >
              <Input 
                value={previousData?.name}  // Show selected product
                readOnly  // Make the input field read-only
                placeholder="Investment product" 
              />
            </Form.Item>
          </Col>

          {/* Question Field */}
          <Col span={5}>
            <Form.Item
              label="Question"
              name={["faqs", index, "question"]}
              rules={[{ required: true, message: "Question is required" }]}
            >
              <TextArea placeholder="Enter Question" rows={3} />
            </Form.Item>
          </Col>

          {/* Answer Field */}
          <Col span={5}>
            <Form.Item
              label="Answer"
              name={["faqs", index, "answer"]}
              rules={[{ required: true, message: "Answer is required" }]}
            >
              <TextArea placeholder="Enter Answer" rows={3} />
            </Form.Item>
          </Col>

          {/* Category Field */}
          <Col span={4}>
            <Form.Item label="Category" name={["faqs", index, "category"]}>
              <Select placeholder="Select Category">
                <Option value="general">General</Option>
                <Option value="investment">Investment</Option>
                <Option value="returns">Returns</Option>
                <Option value="withdrawal">Withdrawal</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Is Visible Switch */}
          <Col span={4}>
            <Form.Item
              label="Is Visible"
              name={["faqs", index, "isVisible"]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>

          {/* Remove FAQ Button */}
          <Col span={1} style={{ display: "flex", alignItems: "center" }}>
            {faqs.length > 1 && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveRow(faq.key)}
                style={{ marginTop: 8 }}
              />
            )}
          </Col>
        </Row>
      ))}

      <Button
        type="dashed"
        onClick={handleAddRow}
        className="mb-4"
        icon={<PlusOutlined />}
      >
        Add FAQ
      </Button>

      <div className="flex justify-between mt-4">
        {/* Previous Button */}
        <Button type="default" onClick={onPrevious}>
          Previous
        </Button>

        {/* Submit Button */}
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default FAQForm;
