import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all withdrawal---------
export const useGetAllWithdrawal = () => {
  return useApiQuery({
    key: ["user-withdrawal"],
    endPoint: `/api/v1/withdrawals`, // need to change this
    method: "GET",
  });
};
export const useGetAllWithdrawalLogs = () => {
  return useApiQuery({
    key: ["user-withdrawal"],
    endPoint: `/api/v1/withdrawals`, // need to change this
    method: "GET",
  });
};

//*********** add user Withdrawal**********
export const useAddUserWithdrawalService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const addUserWithdrawal = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-withdrawal"],
        endPoint: `/api/v1/withdrawals/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
      onSuccess();
      message.success("Successfully added");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { addUserWithdrawal, error };
};

//*********** Update user Withdrawal**********
export const useUpdateWithdrawalService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateUserWithdrawal = async (values, id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-withdrawal", id],
        endPoint: `/api/v1/withdrawals/${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
      queryClient.invalidateQueries({ queryKey: ["user-withdrawal", id] });
      onSuccess();
      message.success("Successfully updated");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { updateUserWithdrawal, error };
};

//*********** Update Withdrawal Status **********
export const useUpdateWithdrawalStatusService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateUserWithdrawalStatus = async (values, id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-withdrawal", id],
        endPoint: `/api/v1/withdrawals/${id}`,
        method: "PATCH",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
      queryClient.invalidateQueries({ queryKey: ["user-withdrawal", id] });
      onSuccess();
      message.success("Successfully updated");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { updateUserWithdrawalStatus, error };
};

//*********** Delete user Withdrawal **********
export const useDeleteUserWithdrawalService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const deleteWithdrawal = async (id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["user-withdrawal"],
        endPoint: `/api/v1/withdrawals/${id}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({ queryKey: ["user-withdrawal"] });
      onSuccess();
      message.success("Successfully deleted");
    } catch (error) {
      onError();
      message.error(error.message);
    }
  };

  return { deleteWithdrawal, error };
};
