import React, { useEffect } from "react";
import { Modal, Form, Input, Button } from "antd";

const EditModal = ({ visible, onCancel, newsItem, onSubmit }) => {
  const [form] = Form.useForm();

  // Fill form fields when newsItem is set
  useEffect(() => {
    if (newsItem) {
      form.setFieldsValue(newsItem);
    }
  }, [newsItem, form]);

  const handleFinish = (values) => {
    onSubmit(values); // Trigger onSubmit with form values
  };

  return (
    <Modal
      title="Edit News"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please enter the news name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Link"
          name="link"
          rules={[{ required: true, message: "Please enter the link" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        {/* Add more form fields as needed */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
