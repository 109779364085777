import { DownloadOutlined } from '@ant-design/icons'; // Importing required icon
import { Button, message, Space } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PaginatedTable from '../../../components/PaginatedTable';
import AdminLayout from '../../../layouts/AdminLayout';
import { useGetAllCryptoWithdrawal, useUpdateUserCryptoWithdrawalStatusService } from '../../../services/withdrawalCryptoService';
import { formatDate } from '../../../utils/formateData';
import Add from './Add';
import Delete from './Delete';
import Edit from './Edit';
import View from './View';

const Index = () => {
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const { data } = useGetAllCryptoWithdrawal();
    const { updateUserCryptoWithdrawal } = useUpdateUserCryptoWithdrawalStatusService();

    const withdrawalColumns = [
        {
            title: 'User',
            dataIndex: `user`,
            key: 'userId',
            render: (text, record) => `${record?.userId?.username} - ${record?.userId?.email} ` || "N/A"
        },
        {
            title: 'Reference',
            dataIndex: 'txid',
            key: 'txid',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => formatDate(date),
        },
        {
            title: 'Asset',
            dataIndex: 'cryptoAsset',
            key: 'cryptoAsset',
            render: (text) => text?.toUpperCase() || 'N/A',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `R ${parseFloat(amount?.$numberDecimal).toFixed(8)}`,
        },
        {
            title: 'Fees',
            dataIndex: 'fee',
            key: 'fee',
            render: (fees) => `${parseFloat(fees?.$numberDecimal).toFixed(8)}`,

        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (text) => text || 'N/A'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
                if (status === 'Pending') {
                    return (
                        <button
                            className="px-3 py-1 text-sm font-semibold text-white bg-yellow-500 rounded hover:bg-yellow-600"
                            onClick={() => handleEditStatus(record)}
                        >
                            Mark Completed
                        </button>
                    );
                }
                return <span className="text-sm font-semibold text-green-600">{status}</span>;
            },
        },
        {
            title: "Actions",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        <Link> <View data={record} /> </Link>
                        <Link>  <Edit data={record} /> </Link>
                        <Link>  <Delete record={record} /> </Link>
                    </Space>
                )
            },
        }
    ];

    const handleEditStatus = async (record) => {
        const payload = {
            status: "Completed",
        }

        updateUserCryptoWithdrawal(
            payload,
            record?._id,
            () => console.log(" successfully updated"),
            () => console.log(" failed  to update the status")
        )
    }

    const handleExport = () => {
        try {
            if (!data?.results?.length) {
                message.warning('No Crypto withdrawal available to export');
                return;
            }

            const headers = ['Crypto withdrawal ID', 'Created At', 'Data'];
            const rows = data.results.map((item) => [
                item._id,
                new Date(item.createdAt).toLocaleString(),
                JSON.stringify(item.data, null, 2),
            ]);

            const csvContent = [headers, ...rows]
                .map((row) => row.map((field) => `"${String(field).replace(/"/g, '""')}"`).join(','))
                .join('\n');

            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Crypto_withdrawal_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            message.success('Exported Crypto withdrawal  successfully');
        } catch (error) {
            message.error('Failed to export Crypto withdrawal ');
        }
    };

    const filters = [
        { name: "record.userId.username", label: "User", type: "text" },
        { name: "txid", label: "Reference", type: "text" },
        { name: "date", label: "Date", type: "text" },
        { name: "asset", label: "Assets", type: "text" },
        { name: "amount", label: "Amount", type: "text" },
        { name: "fees", label: "Fess", type: "text" },
        { name: "status", label: "Status", type: "text" },
        { name: "notes", label: "Notes", type: "text" },
    ];

    return (
        <AdminLayout currentKey={'dw-sub4'} openKey='5'>
            <div className="mx-auto p-6 bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-xl font-semibold"> Client Crypto Withdrawal Requests </h1>
                    <div className="text-xs text-gray-500">
                        Dashboard &gt; Deposits/Withdrawals &gt; Deposits
                    </div>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <button
                        className="px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
                        onClick={() => setIsAddModalVisible(true)}
                    >
                        + Add Withdrawal Request
                    </button>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={handleExport}
                        className="flex items-center px-4 py-2 bg-[#57249c] text-white rounded-lg hover:bg-[#432076] transition"
                    >
                        Export (csv)
                    </Button>
                </div>

                <PaginatedTable
                    fetchEndpoint="/api/v1/crypto-transactions/withdrawals"
                    columns={withdrawalColumns}
                    isSearchable={true}
                    isSelectable={true}
                    filters={filters}
                />

                {isAddModalVisible && (
                    <Add
                        visible={isAddModalVisible}
                        onCancel={() => setIsAddModalVisible(false)}
                        onSubmit={() => { setIsAddModalVisible(false) }}
                    />
                )}
            </div>
        </AdminLayout>
    );
};

export default Index;
