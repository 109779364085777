import React from 'react';
import { Modal, Descriptions } from 'antd';

const ViewModal = ({ visible, onCancel, newsItem }) => {
    return (
        <Modal
            title="View News Details"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            width={600}
        >
            <Descriptions bordered column={1} layout="vertical">
                <Descriptions.Item label="Name">{newsItem?.name || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Link">
                    <a href={newsItem?.link} target="_blank" rel="noopener noreferrer">
                        {newsItem?.link || 'N/A'}
                    </a>
                </Descriptions.Item>
                <Descriptions.Item label="Date">{newsItem?.date ? new Date(newsItem.date).toLocaleDateString() : 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Description">{newsItem?.description || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Source">{newsItem?.source || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Category">{newsItem?.category || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Tags">{newsItem?.tags?.join(', ') || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Author">{newsItem?.author || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Status">{newsItem?.status || 'N/A'}</Descriptions.Item>
                <Descriptions.Item label="Image">
                    {newsItem?.imageUrl ? (
                        <img src={newsItem.imageUrl} alt={newsItem.name} style={{ maxWidth: '100%', borderRadius: '4px' }} />
                    ) : (
                        'N/A'
                    )}
                </Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default ViewModal;
