// src/context/AuthContext.js

import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import LoadingComponent from '../components/LoadingComponent';
import { getQuery, setAuth0User, setAuthorization, setLoggedInUser } from '../services/apiService';
import { message } from 'antd';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [/*isAuthenticated,*/ setIsAuthenticated] = useState(false);
    const [userProfile, setUserProfile] = useState(null);
    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

    const login = async () => {
        try{
            const token = await getAccessTokenSilently();
            const idToken = await getIdTokenClaims();
            setLoggedInUser(idToken);
            setAuthorization(token);

            const response = await getQuery('/api/v1/user/get-user', {authId: user?.sub});

            if(response.status === 200 && response.data.data){ 
                setUserProfile(response.data.data);
            }
        }catch(error){
            console.log(error);
            message.error(`Could not fetch user profile.`);
        }

    }

    useEffect(() => {
        if(isAuthenticated){
            localStorage.setItem('authId', user?.sub);
            console.log("Auth user:", user);
            login();
        }
    }, [isAuthenticated, user]);

    // Show a loading state while the SDK processes authentication
    if (isLoading) {
        //return <div>Loading...</div>;
        return <LoadingComponent />
    }

    if (!isAuthenticated) {
        console.log(window.location.origin);
        return loginWithRedirect();
    }

    const logout = () => setIsAuthenticated(false);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, userProfile, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
