import React, { useEffect } from "react";
import { Form, Input, InputNumber, Select, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getQuery } from "../services/apiService";
import DynamicSelect from "../components/DynamicSelect";
import ValueUnitInput from "../components/ValueInputUnit";

const { Option } = Select;

const InvestmentProductForm = ({ onNextStep, previousData, productId}) => {
  const [form] = Form.useForm();

  // Populate the form with previous data or fetch data if productId is provided
  useEffect(() => {
    if (previousData) {
      form.setFieldsValue(previousData);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await getQuery('/api/v1/investment-product/view');
        if (response) {
          form.setFieldsValue(response);
        }
      } catch (error) {
        message.error("Failed to fetch product data");
      }
    };

    if (productId) {
      fetchData();
    }
  }, [previousData, productId, form]);

  const onFinish = (values) => {
    console.log("Form data:", values);
    onNextStep(values); // Pass form data to the next step
  };

  const beforeUpload = (file) => {
    message.success(`${file.name} file uploaded successfully`);
    return false; // Prevent automatic upload, handle on form submission
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="p-4"
      initialValues={{
        duration: {
          value: '', // Initialize the value field
          unit: 'days', // Default unit
        },
      }}
    >
      <h2 className="text-xl font-bold mb-4">Investment Product Form</h2>

      {/* Name */}
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Name is required" }]}
      >
        <Input />
      </Form.Item>

      {/* Description */}
      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Description is required" }]}
      >
        <Input.TextArea rows={4} />
      </Form.Item>

      {/* Investment Type */}
      <Form.Item
        label="Investment Type"
        name="investmentType"
        rules={[{ required: true, message: "Investment Type is required" }]}
      >
        <Select>
          <Option value="property">Property</Option>
          <Option value="savings">Savings</Option>
        </Select>
      </Form.Item>

      {/* Minimum Amount */}
      <Form.Item
        label="Minimum Amount"
        name="minAmount"
        rules={[{ required: true, message: "Minimum Amount is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Maximum Amount */}
      <Form.Item
        label="Maximum Amount"
        name="maxAmount"
        rules={[{ required: true, message: "Maximum Amount is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* ROI */}
      <Form.Item
        label="Return on Investment (ROI)"
        name="roi"
        rules={[{ required: true, message: "ROI is required" }]}
      >
        <InputNumber min={0} max={100} step={0.01} addonAfter="%" />
      </Form.Item>

      {/* Asset ID */}
      {/*<Form.Item
        label="Asset"
        name="assetId"
        rules={[{ required: true, message: "Asset is required" }]}
      >
        <Input />
      </Form.Item>*/}
      <DynamicSelect
        name="assetId"
        label="Asset"
        endpoint="/api/v1/trading-assets/select-query" // Example API endpoint
        placeholder="Choose an asset"
        valueKey="value"
        labelKey="label"
        //queryParams={{ type: "Fiat" }} // Optional filter
        rules={[{ required: true, message: "Please select an asset!" }]}
      />
      {/* Duration */}
      {/*<Form.Item
        label="Duration"
        name="duration"
        rules={[{ required: true, message: "Duration is required" }]}
      >
        <Input />
      </Form.Item>*/}
      <ValueUnitInput
        label="Duration"
        name="duration"
        units={['days', 'months', 'years']}
        required={false}
        placeholderValue="Enter number"
        placeholderUnit="Select unit"
      />

      {/* Status */}
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: "Status is required" }]}
      >
        <Select>
          <Option value="active">Active</Option>
          <Option value="coming soon">Coming Soon</Option>
          <Option value="inactive">Inactive</Option>
        </Select>
      </Form.Item>

      {/* State */}
      <Form.Item
        label="State"
        name="state"
      >
        <Select>
          <Option value="recommended">Recommended</Option>
          <Option value="popular">Popular</Option>
          <Option value="other">Other</Option>
        </Select>
      </Form.Item>

      {/* Risk */}
      <Form.Item
        label="Risk"
        name="risk"
      >
        <Select>
          <Option value="low">Low</Option>
          <Option value="medium">Medium</Option>
          <Option value="high">High</Option>
        </Select>
      </Form.Item>

      {/* Image URLs */}
      <Form.Item label="Images" name="images">
        <Upload beforeUpload={beforeUpload} listType="picture-card">
          <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>

      <Button type="primary" htmlType="submit" className="mt-4">
        Next
      </Button>
    </Form>
  );
};

export default InvestmentProductForm;
