// src/pages/SubscriptionApproval.jsx

import React, { useEffect, useState } from 'react';
import { Button, message, Space, Breadcrumb } from 'antd';
import axios from 'axios';
import AdminLayout from '../../layouts/AdminLayout';
import PaginatedTable from '../../components/PaginatedTable';
import { getQuery, updatePatch } from '../../services/apiService';

const SubscriptionApproval = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllApplications = async () => {
    setLoading(true);
    try {
      //const response = await axios.get('http://localhost:3000/api/v1/investment-application/index');
      const response = await getQuery('/api/v1/investment-application/index');
      if (response.data.status === 'success') {
        // Flatten the data structure
        const flattenedData = response.data.data.map(app => ({
          key: app._id,
          usernameId: app.userId?.username && app.userId._id ? `${app.userId.username}-${app.userId._id}` : 'N/A',
          status: app.status,
          investmentAmount: app.investmentAmount?.$numberDecimal || '0.00',
          experience: app.experience,
          riskTolerance: app.riskTolerance,
          primaryGoal: app.primaryGoal,
        }));
        setApplications(flattenedData);
      } else {
        message.error('Failed to fetch investment applications.');
      }
    } catch (error) {
      message.error('Error fetching investment applications.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllApplications();
  }, []);

  const handleApprove = async (applicationId) => {
    try {
      /*const response = await axios.patch(`http://localhost:3000/api/v1/investment-application/${applicationId}/status`, {
        status: 'approved'
      });*/
      const response = await updatePatch('/api/v1/investment-application/update-status', { id: applicationId},  {status: 'approved'});
      if (response.status === 200) {
        message.success(`Approved investment application`);
        fetchAllApplications();
      } else {
        message.error('Failed to approve the investment application.');
      }
    } catch (error) {
      message.error('Error approving the investment application.');
    }
  };

  const columns = [
    { title: 'Username-ID', dataIndex: 'usernameId', key: 'usernameId' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Investment Amount ($)', dataIndex: 'investmentAmount', key: 'investmentAmount' },
    { title: 'Experience', dataIndex: 'experience', key: 'experience' },
    { title: 'Risk Tolerance', dataIndex: 'riskTolerance', key: 'riskTolerance' },
    { title: 'Primary Goal', dataIndex: 'primaryGoal', key: 'primaryGoal' },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {record.status !== 'approved' && (
            <Button type="primary" onClick={() => handleApprove(record.key)}>
              Approve
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <AdminLayout currentKey='inv-sub2' openKey='20'>
      <div className="mx-auto p-6 bg-white">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Subscription Approval</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="text-2xl font-semibold mb-6">Subscription Approval</h1>
        <PaginatedTable
          columns={columns}
          dataSource={applications}
          loading={loading}
          rowKey="key"
          pagination={{ pageSize: 10 }}
        />
      </div>
    </AdminLayout>
  );
};

export default SubscriptionApproval;
