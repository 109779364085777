// ../../Modals/Policies/EditPoliciesModal.jsx

import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import axios from 'axios';
import { message } from 'antd';
import moment from 'moment';

const { Option } = Select;

const EditPoliciesModal = ({ visible, onCancel, policy, onUpdateSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (policy && visible) {
      form.setFieldsValue({
        name: policy.name,
        text: policy.text,
        version: policy.version,
        status: policy.status,
        effectiveDate: moment(policy.effectiveDate),
        lastReviewed: moment(policy.lastReviewed),
        reviewedBy: policy.reviewedBy,
      });
    }
  }, [policy, visible, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        const payload = {
          name: values.name,
          text: values.text,
          version: values.version,
          status: values.status,
          effectiveDate: values.effectiveDate.toISOString(),
          lastReviewed: values.lastReviewed.toISOString(),
          reviewedBy: values.reviewedBy,
        };

        axios
          .put(`http://localhost:3000/api/v1/policies/${policy._id}`, payload)
          .then(() => {
            message.success('Policy updated successfully!');
            onUpdateSuccess();
            form.resetFields();
          })
          .catch(error => {
            message.error(
              error.response?.data?.message || 'Failed to update Policy.'
            );
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      visible={visible}
      title="Edit Policy"
      onCancel={onCancel}
      onOk={handleOk}
      okText="Update"
      width={800}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit_policy_form"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            { required: true, message: 'Please input the policy name!' },
            { max: 100, message: 'Name cannot exceed 100 characters!' },
          ]}
        >
          <Input placeholder="Enter policy name" />
        </Form.Item>

        <Form.Item
          name="text"
          label="Text"
          rules={[
            { required: true, message: 'Please input the policy text!' },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Enter policy text" />
        </Form.Item>

        <Form.Item
          name="version"
          label="Version"
          rules={[
            { required: true, message: 'Please input the version!' },
            { pattern: /^\d+(\.\d+)?$/, message: 'Version must be a valid number!' },
          ]}
        >
          <Input placeholder="Enter version" />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[
            { required: true, message: 'Please select a status!' },
          ]}
        >
          <Select placeholder="Select status">
            <Option value="Published">Published</Option>
            <Option value="Draft">Draft</Option>
            <Option value="Archived">Archived</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="effectiveDate"
          label="Effective Date"
          rules={[
            { required: true, message: 'Please select the effective date!' },
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="lastReviewed"
          label="Last Reviewed"
          rules={[
            { required: true, message: 'Please select the last reviewed date!' },
          ]}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="reviewedBy"
          label="Reviewed By (User ID)"
          rules={[
            { required: true, message: 'Please input the reviewer\'s User ID!' },
            { pattern: /^[0-9a-fA-F]{24}$/, message: 'Please enter a valid User ID!' },
          ]}
        >
          <Input placeholder="Enter User ID" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditPoliciesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  policy: PropTypes.object.isRequired,
  onUpdateSuccess: PropTypes.func.isRequired,
};

export default EditPoliciesModal;
