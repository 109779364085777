// src/pages/News.js

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import AdminLayout from "../../layouts/AdminLayout";
import PaginatedTable from "../../components/PaginatedTable";
import AddNewsModal from "../../components/Modals/News/AddNewsModal";
import EditModal from "../../components/Modals/News/EditModal";
import ViewModal from "../../components/Modals/News/ViewModal";
import DeleteModal from "../../components/Modals/News/DeleteModal";
import { debounce } from "lodash";
import { message, Space, Button, Breadcrumb } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const News = () => {
  const [allNews, setAllNews] = useState([]); // Store all news items
  const [filteredNews, setFilteredNews] = useState([]); // Store filtered news items
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Modal state variables
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  // Function to fetch news data
  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await axios.get("http://localhost:3000/api/v1/news/index");
      if (response.status === 200 && Array.isArray(response.data)) {
        const processedData = response.data.map((item) => ({
          _id: item._id || "",
          name: item.name || "N/A",
          link: item.link || "N/A",
          date: item.date || "N/A",
          description: item.description || "N/A",
          source: item.source || "N/A",
          category: item.category || "N/A",
          tags: Array.isArray(item.tags) ? item.tags : [],
          author: item.author || "N/A",
          status: item.status || "N/A",
        }));
        setAllNews(processedData);
        setFilteredNews(processedData); // Initialize with all data
      } else {
        throw new Error("Data is not in expected format");
      }
    } catch (error) {
      setError("Failed to fetch news: " + error.message);
      console.error("Error fetching news:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  // Debounced search handler
  const handleSearch = useCallback(
    debounce((filters) => {
      // Apply front-end filtering based on the filters object
      let filtered = allNews;

      // Global Search
      if (filters.globalSearch) {
        const searchText = filters.globalSearch.toLowerCase();
        filtered = filtered.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(searchText)
        );
      }

      // Column-specific Filters (if any)
      // Example: If you have specific filters for certain columns, handle them here
      // For now, we'll assume only global search is required

      setFilteredNews(filtered);
    }, 300),
    [allNews]
  );

  // Function to format date as "07 November 2024, 07:30"
  const formatDate = (dateString) => {
    if (!dateString || dateString === "N/A") return "N/A";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid Date";
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  };

  // Modal handlers
  const handleAddNews = () => setIsAddModalVisible(true);

  const handleEdit = (record) => {
    setSelectedNews(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = (record) => {
    setSelectedNews(record);
    setIsDeleteModalVisible(true);
  };

  const handleView = (record) => {
    setSelectedNews(record);
    setIsViewModalVisible(true);
  };

  const handleDeleteNews = async (id) => {
    try {
      const response = await axios.delete(`http://localhost:3000/api/v1/news/${id}`);
      if (response.status === 200) {
        message.success("News deleted successfully");
        fetchNews(); // Refresh the news list after deletion
      }
    } catch (error) {
      console.error("Error deleting news:", error.response?.data || error.message);
      message.error(
        error.response?.data?.message || "An error occurred while deleting the news."
      );
    } finally {
      setIsDeleteModalVisible(false);
    }
  };

  // Function to convert JSON data to CSV
  const convertToCSV = (data) => {
    const headers = [
      "Name",
      "Link",
      "Date",
      "Description",
      "Source",
      "Category",
      "Tags",
      "Author",
      "Status",
    ];
    const rows = data.map((item) => [
      item.name,
      item.link,
      formatDate(item.date),
      item.description,
      item.source,
      item.category,
      item.tags.join(", "),
      item.author,
      item.status,
    ]);

    const csvContent = [headers, ...rows]
      .map((row) =>
        row
          .map((item) => `"${String(item).replace(/"/g, '""')}"`)
          .join(",")
      )
      .join("\n");

    return csvContent;
  };

  const handleExport = () => {
    try {
      if (filteredNews.length === 0) {
        message.warning("No news items available to export");
        return;
      }

      const csvData = convertToCSV(filteredNews);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "news_export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      message.success("Exported news data successfully");
    } catch (error) {
      message.error("Error exporting news data.");
      console.error("Export error:", error);
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          View Link
        </a>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => (text ? formatDate(text) : "N/A"),
    },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Source", dataIndex: "source", key: "source" },
    { title: "Category", dataIndex: "category", key: "category" },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => tags.join(", "),
    },
    { title: "Author", dataIndex: "author", key: "author" },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  if (error) {
    return (
      <AdminLayout>
        <div className="mx-auto p-6 bg-white">
          <div className="text-red-500">Error: {error}</div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="mx-auto p-6 bg-white shadow rounded-lg">
        {/* Breadcrumb */}
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>News</Breadcrumb.Item>
        </Breadcrumb>

        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">News</h1>
          <Space>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleExport}
              style={{ backgroundColor: "#57249c", borderColor: "#57249c" }}
            >
              Export CSV
            </Button>
            <Button
              type="primary"
              onClick={handleAddNews}
              style={{ backgroundColor: "#22c6ab", borderColor: "#22c6ab" }}
            >
              + Add News
            </Button>
          </Space>
        </div>

        {/* Search Input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search news..."
            onChange={(e) => handleSearch({ globalSearch: e.target.value })}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        {/* Table */}
        <PaginatedTable
          columns={columns}
          dataSource={filteredNews}
          itemsPerPage={15}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onView={handleView}
          loading={loading}
        />

        {/* Modals */}
        {isAddModalVisible && (
          <AddNewsModal
            visible={isAddModalVisible}
            onCancel={() => setIsAddModalVisible(false)}
            onSubmit={() => {
              fetchNews(); // Refresh the news list after adding
              setIsAddModalVisible(false); // Close the modal after submission
            }}
          />
        )}

        {isEditModalVisible && (
          <EditModal
            visible={isEditModalVisible}
            onCancel={() => setIsEditModalVisible(false)}
            newsItem={selectedNews}
            onSubmit={() => {
              fetchNews();
              setIsEditModalVisible(false);
            }}
          />
        )}

        {isDeleteModalVisible && selectedNews && (
          <DeleteModal
            visible={isDeleteModalVisible}
            onCancel={() => setIsDeleteModalVisible(false)}
            data={selectedNews} // Pass the entire news item
            onDelete={handleDeleteNews}
          />
        )}

        {isViewModalVisible && selectedNews && (
          <ViewModal
            visible={isViewModalVisible}
            onCancel={() => setIsViewModalVisible(false)}
            data={selectedNews}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default News;
