import { useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all notifications---------
export const useGetAllNotifications = (page = 1, limit = 10) => {
  return useApiQuery({
    key: ["push-notifications", page, limit],
    endPoint: `/api/v1/pushnotifications/all?page=${page}&limit=${limit}`,
    method: "GET",
  });
};

export const useCreateNotificationService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const createNotification = async (values, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["push-notifications"],
        endPoint: `/api/v1/pushnotifications/create`,
        method: "POST",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["push-notifications"] });
      message.success("Notification created successfully!");
      onSuccess();
    } catch (err) {
      message.error(err.message || "Failed to create notification.");
      onError();
    }
  };

  return { createNotification, error };
};

//* ---------Update Notification---------
export const useUpdateNotificationService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const updateNotification = async (values, id, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["push-notifications", id],
        endPoint: `/api/v1/pushnotifications/update?&pushnoticationId=${id}`,
        method: "PUT",
        bodyContent: values,
      });
      queryClient.invalidateQueries({ queryKey: ["push-notifications"] });
      onSuccess();
      message.success("Notification updated successfully!");
    } catch (err) {
      onError();
      message.error(err.message || "Failed to update notification.");
    }
  };

  return { updateNotification, error };
};

//* ---------Delete Notification---------
export const useDeleteNotificationService = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, error } = useApiMutation();

  const deleteNotification = async (notificationId, onSuccess, onError) => {
    try {
      await mutateAsync({
        key: ["push-notifications"],
        endPoint: `/api/v1/pushnotifications/delete?&pushnoticationId=${notificationId}`,
        method: "DELETE",
      });
      queryClient.invalidateQueries({ queryKey: ["push-notifications"] });
      message.success("Notification deleted successfully!");
      onSuccess();
    } catch (err) {
      message.error(err.message || "Failed to delete notification.");
      onError();
    }
  };

  return { deleteNotification, error };
};
