// ../../Pages/Admin/Policies.jsx

import React, { useState, useEffect } from 'react';
import {
  Table,
  Button,
  Space,
  message,
  Input,
  Row,
  Col,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { FaEye, FaEdit } from 'react-icons/fa'; // Importing React Icons
import axios from 'axios';
import ViewPoliciesModal from '../../components/Modals/Policies/ViewPoliciesModal';
import EditPoliciesModal from '../../components/Modals/Policies/EditPoliciesModal';

const { Search } = Input;

const Policies = () => {
  const [policies, setPolicies] = useState([]);
  const [filteredPolicies, setFilteredPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const fetchPolicies = () => {
    setLoading(true);
    axios
      .get('http://localhost:3000/api/v1/policies')
      .then(response => {
        setPolicies(response.data);
        setFilteredPolicies(response.data);
        setLoading(false);
      })
      .catch(() => {
        message.error('Failed to fetch Policies.');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPolicies();
  }, []);

  const handleView = (record) => {
    setSelectedPolicy(record);
    setViewModalVisible(true);
  };

  const handleEdit = (record) => {
    setSelectedPolicy(record);
    setEditModalVisible(true);
  };

  const handleSearch = (value) => {
    const filtered = policies.filter(policy =>
      policy.name.toLowerCase().includes(value.toLowerCase()) ||
      policy.version.toLowerCase().includes(value.toLowerCase()) ||
      policy.text.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredPolicies(filtered);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: text => <strong style={{ color: '#1890ff' }}>{text}</strong>,
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
      sorter: (a, b) => a.version.localeCompare(b.version),
      render: text => <span style={{ color: '#595959' }}>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'text',
      key: 'text',
      sorter: (a, b) => a.text.localeCompare(b.text),
      render: text => <span style={{ color: '#8c8c8c' }}>{text}</span>,
    },
    {
      title: 'Effective Date',
      dataIndex: 'effectiveDate',
      key: 'effectiveDate',
      sorter: (a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate),
      render: date => <span style={{ color: '#52c41a' }}>{new Date(date).toLocaleDateString()}</span>,
    },
    {
      title: 'Last Reviewed',
      dataIndex: 'lastReviewed',
      key: 'lastReviewed',
      sorter: (a, b) => new Date(a.lastReviewed) - new Date(b.lastReviewed),
      render: date => <span style={{ color: '#faad14' }}>{new Date(date).toLocaleDateString()}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              type="link"
              icon={<FaEye style={{ color: '#1890ff' }} />}
              onClick={() => handleView(record)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="link"
              icon={<FaEdit style={{ color: '#52c41a' }} />}
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="p-8 bg-gradient-to-r from-white to-gray-100 shadow-lg rounded-xl">
      <Row justify="space-between" align="middle" className="mb-6">
        <Col>
          <h2 className="text-3xl font-bold text-blue-600">Policies</h2>
        </Col>
        <Col>
          <Space>
            <Search
              placeholder="Search Policies"
              onSearch={handleSearch}
              enterButton={<SearchOutlined />}
              allowClear
              style={{ width: 300 }}
            />
          </Space>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredPolicies}
        rowKey="_id"
        loading={loading}
        scroll={{ x: 1000 }}
        pagination={{ pageSize: 5 }}
        bordered
        className="ant-table-striped ant-table-bordered"
        style={{ backgroundColor: '#ffffff' }}
      />

      {/* View Modal */}
      <ViewPoliciesModal
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        policy={selectedPolicy}
      />

      {/* Edit Modal */}
      <EditPoliciesModal
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        policy={selectedPolicy}
        onUpdateSuccess={() => {
          message.success('Policy updated successfully.');
          fetchPolicies();
          setEditModalVisible(false);
        }}
      />
    </div>
  );
};

export default Policies;
