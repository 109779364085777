import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation } from "../hooks/useApiRequest";

// ********** ADD THE USER TO MONDAY.COM **********
export const useAddToMondayService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addUserToMonday = async (userId, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["monday"],
                endPoint: `/api/v1/leads/monday-upload?userId=${userId}`,
                method: "POST",
                bodyContent: {},
            });
            queryClient.invalidateQueries({ queryKey: ["monday"] });
            onSuccess();
            message.success('Successfully added to monday.com');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addUserToMonday, error };
};

