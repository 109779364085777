import { DatePicker, Input, Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from 'react';
import { getQuery } from '../services/apiService';
import moment from "moment";

const { Option } = Select;

const PaginatedTable = ({
  fetchEndpoint,
  fetchParams = {},
  columns,
  extractData = (response) => response.data.data.results,
  extractPagination = (response) => ({
    totalItems: response.data.data.totalItems,
    currentPage: response.data.data.currentPage,
    limit: response.data.data.limit,
    totalPages: response.data.data.totalPages,
  }),
  actionColumn = [],
  filters = [],
  refresh = false
}) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 20,
  });
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  //const 

  const fetchData = async (pageNumber = 1, pageSize = 20, filters = {}) => {
    setLoading(true);
    try {
      const params = {
        page: pageNumber,
        limit: pageSize,
        ...fetchParams,
        ...filters,
      };
      const response = await getQuery(fetchEndpoint, params);
      console.log(response.data.data);

      const newData = extractData(response);
      const newPagination = extractPagination(response);

      setData(newData);
      setPagination({
        ...newPagination,
        startIndex: (pageNumber - 1) * pageSize + 1,
        endIndex: Math.min(pageNumber * pageSize, newPagination.totalItems),
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadItems = async () => {
      await fetchData(pagination.currentPage, pagination.limit);
    };

    loadItems();
  }, [filterValues, pagination.currentPage, refresh]);

  const handleTableChange = (page, pageSize) => {
    fetchData(page, pageSize, filterValues);
  };

  const handleFilterChange = (filterKey, value) => {
    const newFilters = { ...filterValues, [filterKey]: value };
    setFilterValues(newFilters);
    fetchData(1, pagination.limit, newFilters);
  };

  const filterRow = filters.reduce((acc, filter) => {
    switch (filter.type) {
    case 'text':
      acc[filter.name] = (
      <Input
        key={filter.name}
        placeholder={`Search ${filter.label}`}
        value={filterValues[filter.name]}
        onChange={(e) => handleFilterChange(filter.name, e.target.value)}
      />
      );
      break;
    case 'dropdown':
      acc[filter.name] = (
      <Select
        key={filter.name}
        value={filterValues[filter.name]}
        onChange={(value) => handleFilterChange(filter.name, value)}
        placeholder={`Select ${filter.label}`}
      >
        {filter.options.map((option) => (
        <Option key={option} value={option}>
          {option.label}
        </Option>
        ))}
      </Select>
      );
      break;
    case 'date':
      acc[filter.name] = (
      <DatePicker
        key={filter.name}
        value={filterValues[filter.name] ? moment(filterValues[filter.name]) : null}
        onChange={(date) => handleFilterChange(filter.name, date ? date.format('YYYY-MM-DD') : '')}
      />
      );
      break;
    default:
      break;
    }
    return acc;
  }, {});
  /*const filterRow = filters.map((filter) => {
    switch (filter.type) {
    case 'text':
      return (
      <Input
        key={filter.name}
        placeholder={`Search ${filter.label}`}
        value={filterValues[filter.name]}
        onChange={(e) => handleFilterChange(filter.name, e.target.value)}
      />
      );
    case 'dropdown':
      return (
      <Select
        key={filter.name}
        value={filterValues[filter.name]}
        onChange={(value) => handleFilterChange(filter.name, value)}
        placeholder={`Select ${filter.label}`}
      >
        {filter.options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
        ))}
      </Select>
      );
    case 'date':
      return (
      <DatePicker
        key={filter.name}
        value={filterValues[filter.name] ? moment(filterValues[filter.name]) : null}
        onChange={(date) => handleFilterChange(filter.name, date ? date.format('YYYY-MM-DD') : '')}
      />
      );
    default:
      return null;
    }
  });*/

  const columnsWithActions = [
    ...columns,
    //actionColumn,
  ];

  return (
    <div>
      {/* Pagination info */}
      {/* Pagination controls */}
      <div className="flex justify-between items-end mt-4 w-full py-2">
        {/* Pagination info at the bottom */}
        <div>
          Showing {pagination.startIndex} to {pagination.endIndex} of {pagination.totalItems} items
        </div>
        <Pagination
          current={pagination.currentPage}
          pageSize={pagination.limit}
          total={pagination.totalItems}
          onChange={handleTableChange}
          showSizeChanger
          onShowSizeChange={handleTableChange}
        //showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
      </div>

      {/* Table */}
      <Table
        columns={columnsWithActions}
        dataSource={data}
        /*dataSource={[
        {
          key: 'filter-row',
          isFilterRow: true,
          ...filterRow,  // Merge filter inputs with the row data
        },
        //...data, // Actual data rows
        ].concat(data)}*/
        pagination={false}
        loading={loading}
        rowKey="id"
        //rowClassName={(record) => record.isFilterRow ? 'filter-row' : ''}
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
      />

      {/* Pagination controls */}
      <div className="flex justify-between items-end mt-4 w-full py-2">
        {/* Pagination info at the bottom */}
        <div>
          Showing {pagination.startIndex} to {pagination.endIndex} of {pagination.totalItems} items
        </div>
        <Pagination
          current={pagination.currentPage}
          pageSize={pagination.limit}
          total={pagination.totalItems}
          onChange={handleTableChange}
          showSizeChanger
          onShowSizeChange={handleTableChange}
        //showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        />
      </div>
    </div>
  );
};

export default PaginatedTable;

