import { useApiQuery } from '../hooks/useApiRequest';

//* ---------Get all assets---------
export const useGetAllAssets = (filter) => {
    return useApiQuery({
        key: ['assets',],
        endPoint: `/api/v1/trading-assets/get-assets?${filter}`,
        method: 'GET',
    });
};


export const useGetAllChains = (assetId) => {
    return useApiQuery({
        key: ["chains"],
        endPoint: `/api/v1/trading-assets/get-chains?assetId=${assetId}`,
        method: "GET",
    });
};
