// ViewWithdrawalModal.js

import { EyeOutlined } from '@ant-design/icons';
import { Button, Descriptions, Modal } from 'antd';
import React, { useState } from 'react';

const ViewWithdrawalModal = ({ data }) => {
    const [visible, setVisible] = useState(false);
    const toggleView = () => setVisible(!visible);

    if (!visible) {
        return (
            <Button
                type="text"
                icon={<EyeOutlined />}
                style={{
                    padding: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={toggleView}
            />
        );
    }

    return (
        <Modal
            visible={visible}
            title="Withdrawal"
            onCancel={toggleView}
            footer={null}
        >
            {data && (
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="Date">
                        {new Date(data?.date).toLocaleString() || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Asset">
                        {data?.asset.toUpperCase() || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Amount">
                        {parseFloat(data?.amount?.$numberDecimal).toFixed(8 || "N/A")}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fees">
                        {parseFloat(data?.fees?.$numberDecimal).toFixed(8) || "N/A"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total">
                        {(
                            parseFloat(data?.amount?.$numberDecimal) +
                            parseFloat(data?.fees?.$numberDecimal)
                        ).toFixed(8)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Notes">
                        {data?.notes || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                        {data?.status}
                    </Descriptions.Item>
                    <Descriptions.Item label="Transaction ID">
                        {data?.txid || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="User Address ID">
                        {data?.userAddressId || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Trading Asset Chain ID">
                        {data?.tradingAssetChainId || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Fireblocks ID">
                        {data?.fireblocksId || 'N/A'}
                    </Descriptions.Item>
                </Descriptions>
            )}
        </Modal>
    );
};

export default ViewWithdrawalModal;
