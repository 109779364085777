// ../../Modals/Policies/ViewPoliciesModal.jsx

import React from 'react';
import { Modal, Descriptions } from 'antd';
import PropTypes from 'prop-types';

const ViewPoliciesModal = ({ visible, onCancel, policy }) => {
  return (
    <Modal
      visible={visible}
      title="View Policy"
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      {policy ? (
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Name">{policy.name}</Descriptions.Item>
          <Descriptions.Item label="Text">{policy.text}</Descriptions.Item>
          <Descriptions.Item label="Version">{policy.version}</Descriptions.Item>
          <Descriptions.Item label="Status">{policy.status}</Descriptions.Item>
          <Descriptions.Item label="Effective Date">
            {new Date(policy.effectiveDate).toLocaleDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="Last Reviewed">
            {new Date(policy.lastReviewed).toLocaleDateString()}
          </Descriptions.Item>
          <Descriptions.Item label="Reviewed By">
            {policy.reviewedBy ? policy.reviewedBy : 'N/A'}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <p>No policy data available.</p>
      )}
    </Modal>
  );
};

ViewPoliciesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  policy: PropTypes.object,
};

export default ViewPoliciesModal;
