import React, { useEffect, useState } from "react";
import { message, Table } from "antd";
import { getQuery } from "../../../services/apiService";

const WalletsTab = ({ activeTab, userID }) => {
    const [wallets, setWallets] = useState([]);
    console.log(userID);

    useEffect(() => {
        const fetchBalances = async () => {
            try {
                const response = await getQuery("/api/v1/user-wallets/get", { userID });
                if (response.status === 200 && response.data.status && response.data.data) {
                    setWallets(response.data.data);
                    console.log(response.data);
                    return;
                }
            } catch (error) {
                message.error("Balance data for this user not found.");
            }
        };

        fetchBalances();
    }, [userID]);

    const columns = [
        {
            title: "Asset",
            dataIndex: ["assetId", "assetName"],
        },
        {
            title: "Chain",
            dataIndex: ["chainId", "chain"],
            key: "chainId",
        },
        {
            title: "Wallet Address",
            dataIndex: "walletId",
            key: "walletId",
            render: (text) => (
                <div style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text}
                </div>
            ),
        },
        {
            title: "Legacy Wallet Address",
            dataIndex: "walletIdLegacy",
            key: "walletIdLegacy",
            render: (text) => (
                <div style={{ wordWrap: "break-word", wordBreak: "break-word", whiteSpace: "normal" }}>
                    {text}
                </div>
            ),
        },
    ];

    return <Table dataSource={wallets} columns={columns} />;
};

export default WalletsTab;
