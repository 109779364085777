import {
  DashOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  LogoutOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import {
  faCoins,
  faCreditCard,
  faMoneyBill,
  faPoll,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LogoutButton from "../components/LogoutButton";

export const items = [
  {
    label: (
      <Link to="/admin/dashboard">
        <span className="font-light"> Dashboard</span>
      </Link>
    ),
    key: "2",
    icon: <DashboardOutlined />,
  },

  {
    label: "Clients",
    key: "4",
    icon: <UsergroupAddOutlined />,
    children: [
      {
        key: "sub1",
        label: (
          <Link to="/admin/clients">
            <span className="font-light"> Overview</span>
          </Link>
        ),
      },
    ],
  },
  {
    label: "Deposits/Withdrawals",
    key: "5",
    icon: <FontAwesomeIcon icon={faMoneyBill} />,
    children: [
      {
        key: "dw-sub1",
        label: (
          <Link to="/admin/deposits">
            <span className="font-light"> Deposits</span>
          </Link>
        ),
      },
      {
        key: "dw-sub2",
        label: (
          <Link to="/admin/crypto-deposits">
            <span className="font-light"> Crypto Deposits </span>
          </Link>
        ),
      },
      {
        key: "dw-sub3",
        label: (
          <Link to="/admin/withdrawals">
            <span className="font-light"> Withdrawals </span>
          </Link>
        ),
      },
      {
        key: "dw-sub4",
        label: (
          <Link to="/admin/crypto-withdrawals">
            <span className="font-light"> Crypto Withdrawals </span>
          </Link>
        ),
      },
      {
        key: "dw-sub5",
        label: (
          <Link to="/admin/deposit-logs">
            <span className="font-light"> Deposits Logs </span>
          </Link>
        ),
      },
      {
        key: "dw-sub6",
        label: (
          <Link to="/admin/withdrawal-logs">
            <span className="font-light"> Withdrawal Logs </span>
          </Link>
        ),
      },
    ],
  },

  /*{
    label: "OTC",
    key: "6",
    icon: <FontAwesomeIcon icon={faPoll} />,
    children: [
      {
        key: "otc-sub1",
        label: (
          <Link to="/admin/liquidity-pool">
            <span className="font-light"> Liquidity Pool </span>
          </Link>
        ),
      },
      {
        key: "otc-sub2",
        label: (
          <Link to="/admin/update-pool">
            <span className="font-light"> Adjust LP </span>
          </Link>
        ),
      },
      {
        key: "otc-sub3",
        label: (
          <Link to="/admin/lp-history">
            <span className="font-light"> LP History </span>
          </Link>
        ),
      },
      {
        key: "otc-sub4",
        label: (
          <Link to="/admin/lp-alert">
            <span className="font-light"> LP Alert </span>
          </Link>
        ),
      },
      {
        key: "otc-sub5",
        label: (
          <Link to="/admin/transaction-history">
            <span className="font-light"> Transaction History </span>
          </Link>
        ),
      },
      {
        key: "otc-sub6",
        label: (
          <Link to="/admin/trading-fees">
            <span className="font-light"> Trading Fees </span>
          </Link>
        ),
      },
    ],
  },*/
//{
  //     label: 'Brokers',
  //     key: '7',
  //     icon: <FontAwesomeIcon icon={faUserSecret} />,
  //     children: [
  //         {
  //             key: 'broker-sub1',
  //             label: (
  //                 <Link to="/admin/broker-applications"><span className='font-light'> Applications </span></Link>
  //             ),
  //         },
  //         {
  //             key: 'broker-sub2',
  //             label: (
  //                 <Link to="/admin/broker-overview"><span className='font-light'> Overview </span></Link>
  //             ),
  //         },
  //         {
  //             key: 'broker-sub3',
  //             label: (
  //                 <Link to="/admin/broker-trades"><span className='font-light'> Trades </span></Link>
  //             ),
  //         },
  //         {
  //             key: 'broker-sub4',
  //             label: (
  //                 <Link to="/admin/broker-commissions"><span className='font-light'> Commissions </span></Link>
  //             ),
  //         },
  //     ],
  // },
  // {
  //     label: 'Arbitrage',
  //     key: '8',
  //     icon: <FontAwesomeIcon icon={faArchive} />,
  //     children: [
  //         {
  //             key: 'arb-sub1',
  //             label: (
  //                 <Link to="/admin/arbitrage-batches"><span className='font-light'> Batches </span></Link>
  //             ),
  //         },
  //     ],
  // }
  {
    label: (
      <Link to="/admin/trading-assets">
        <span className="font-light"> Assets </span>
      </Link>
    ),
    key: "19",
    icon: <FontAwesomeIcon icon={faCreditCard} />,
  },
  {
    label: "Investments",
    key: "20",
    icon: <FontAwesomeIcon icon={faCoins} />,
    children: [
      {
        key: "inv-sub1",
        label: (
          <Link to="/admin/manage-investments">
            <span className="font-light"> Manage Investments </span>
          </Link>
        ),
      },
      {
        key: "inv-sub2",
        label: (
          <Link to="/admin/subscription-approval">
            <span className="font-light"> Subscription Approval </span>
          </Link>
        ),
      },
      {
        key: "inv-sub3",
        label: (
          <Link to="/admin/user-summary">
            <span className="font-light"> User Summary </span>
          </Link>
        ),
      },
    ],
  },
  {
    label: "Data Sets",
    key: "9",
    icon: <DashOutlined />,
  },
  {
    label: "News",
    key: "10",
    icon: <DatabaseOutlined />,
    children: [
      {
        key: "news-sub1",
        label: (
          <Link to="/admin/all-news">
            <span className="font-light"> Manage News </span>
          </Link>
        ),
      },
      // {
      //     key: 'news-sub2',
      //     label: (
      //         <Link to="/admin/add-news"><span className='font-light'> Add New </span></Link>
      //     ),
      // },
    ],
  },
  // {
  //     label: 'Upsell',
  //     key: '11',
  //     icon: <DatabaseOutlined />,
  // },
  // {
  //     label: 'Emails',
  //     key: '12',
  //     icon: <DatabaseOutlined />,
  //     children: [
  //         {
  //             key: 'email-sub1',
  //             label: (
  //                 <Link to="/admin/emails"><span className='font-light'> Manage </span></Link>
  //             ),
  //         },
  //     ],
  // },
  {
    label: "Settings",
    key: "13",
    icon: <SettingOutlined />,
    children: [
      {
        key: "setting-sub1",
        label: (
          <Link to="/admin/settings-system">
            <span className="font-light"> System </span>
          </Link>
        ),
      },
      {
        key: "setting-sub2",
        label: (
          <Link to="/admin/settings-contact">
            <span className="font-light"> Contact </span>
          </Link>
        ),
      },
      {
        key: "setting-sub3",
        label: (
          <Link to="/admin/settings-api-keys">
            <span className="font-light"> API Keys </span>
          </Link>
        ),
      },
    ],
  },

  // {
  //   label: (
  //     <Link to="/admin/admin-managers">
  //       <span className="font-light"> Admin Managers </span>
  //     </Link>
  //   ),
  //   key: "14",
  //   icon: <DatabaseOutlined />,
  // },

  {
    label: "PushNotification",
    key: "30",
    icon: <NotificationOutlined />,
    children: [
      {
        key: "sub30",
        label: (
          <Link to="/admin/PushNotification">
            <span className="font-light"> Push Notification</span>
          </Link>
        ),
      },
    ],
  },
  {
    label: (
      <Link to="/admin/admin-users">
        <span className="font-light"> Admin Managers </span>
      </Link>
    ),
    key: "14",
    icon: <DatabaseOutlined />,
  },

  {
    label: (
      <Link to="/admin/policies">
        <span className="font-light"> Policies </span>
      </Link>
    ),
    key: "15",
    icon: <DatabaseOutlined />,
  },
  {
    label: (
      <Link to="/admin/audit-logs">
        <span className="font-light"> Audit Logs </span>
      </Link>
    ),
    key: "16",
    icon: <DatabaseOutlined />,
  },
  {
    label: (
      <Link to="/admin/2fa">
        <span className="font-light"> 2FA </span>
      </Link>
    ),
    key: "17",
    icon: <DatabaseOutlined />,
  },
  {
    label: (
      
      
        <LogoutButton />
    
    ),
    key: "18",
    icon: <LogoutOutlined />,
  },
];
