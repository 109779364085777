import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Auth0 from '@auth0/auth0-react';

const AUTH_SESSION_KEY = "auth_user";
const ACCOUNT_SESSSION_KEY = "user_account";

const baseURL = process.env.REACT_APP_BASE_URL;
console.log(baseURL);
// Set default content type and base URL for axios
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = baseURL; //"https://192.168.101.107:3001/" //"http://localhost:3000"; // Replace with your actual API URL
//const testUser = "auth0|66d4707b7724f24a97972a5a";
//const testUser = 'auth0|66d95413e1ac983f011fb379'
//axios.defaults.baseURL = "https://80eight.zilo-aws.co.za/"
/*const auth = new Auth0({
  domain: "dev-fu88hpvhk3wk7i2x.us.auth0.com",
  clientId: "S8cNtAAK2gCE6gY4SgOQiddQSbXUe08J",
});*/
// Intercepting to capture errors
/*axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message;

    if (error && error.response) {
      switch (error.response.status) {
        case 401:
            message = "Invalid credentials";
        break;
        case 403:
            message = "Access Forbidden";
            window.location.href = "/access-denied";
        break;
        case 404:
            message = "Sorry! the data you are looking for could not be found";
        break;
        default:
            message = error.response.data.message || error.message || error;
      }
      return Promise.reject(message);
    }
  }
);*/

axios.interceptors.request.use(async (config) => {
  const authUser = getUserFromSession();
  console.log(authUser);
  const { sub } = authUser;
  console.log(sub);
  const additionalQueryParam = sub; //testUser; //user.sub;
  config.params = {
    ...config.params, // Preserve existing params
    authId: additionalQueryParam, // Add the new parameter
  };
  return config;
}, (error) => {
  // Handle errors in request creation
  return Promise.reject(error);
});

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to set the default authorization header
const setAuthorization = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

// Function to get user from session storage
const getUserFromSession = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? JSON.parse(user) : null;
};

// Fetch data with GET method
const get = (url, params) => {
  const queryString = params
    ? "?" + new URLSearchParams(params).toString()
    : "";
  return axios.get(url + queryString);
};

const getQuery = (url, params) => {
  return axios.get(url, { params });
};

// Fetch a file
const getFile = (url, params) => {
  const queryString = params
    ? "?" + new URLSearchParams(params).toString()
    : "";
  return axios.get(url + queryString, { responseType: "blob" });
};

// Create a new resource with POST method
const create = (url, data) => {
  return axios.post(url, data);
};

// Update data with PATCH method
const updatePatch = (url, params = {}, data = {}, config = {}) => {
  return axios.patch(url, data, {
    ...config,
    params, // Add query parameters here
  });
};

// Update data with PUT method
const update = (url, params = {}, data = {}, config = {}) => {
  //return axios.put(url, data);
  return axios.patch(url, data, {
    ...config,
    params, // Add query parameters here
  });
};

// Delete a resource
const deleteResource = (url, params = {}, data = {}, config = {}) => {
  //return axios.delete(url);
  return axios.delete(url, {
    ...config,
    params,  // Query parameters should be included here
    data     // Body data (in case of DELETE, data might be required)
  });
};

// Create a resource with file upload
const createWithFile = (url, data) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  return axios.post(url, formData, {
    headers: {
      ...axios.defaults.headers,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Update with file upload
const updateWithFile = (url, data) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }

  return axios.patch(url, formData, {
    headers: {
      ...axios.defaults.headers,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Check if the user is authenticated
const isUserAuthenticated = () => {
  const user = getLoggedInUser();
  if (!user) {
    return false;
  }

  const decoded = jwtDecode(user.token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn("access token expired");
    return false;
  }
  return true;
};

// Set logged-in user
const setLoggedInUser = (session) => {
  console.log('Session:', session);
  if (session) {
    sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
  } else {
    sessionStorage.removeItem(AUTH_SESSION_KEY);
  }
};

// Get logged-in user
const getLoggedInUser = () => {
  return getUserFromSession();
};

// Set user in session
const setUserInSession = (modifiedUser) => {
  let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
  if (userInfo) {
    const { token, user } = JSON.parse(userInfo);
    setLoggedInUser({ token, ...user, ...modifiedUser });
  }
};

// Set auth0 user session
/*const setAuth0User = (authUser) => {
  let user = sessionStorage.getItem(AUTH_SESSION_KEY);
  if(user){
    const auth0User = JSON.parse(user);
    console.log(''auth0User);
    setLoggedInUser(auth0User);
  }
}*/

// Check if token is available in session
const user = getUserFromSession();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export const axiosInstance = axios;

export {
  create, createWithFile, deleteResource, get, getFile, getLoggedInUser, getQuery, isUserAuthenticated,
  setLoggedInUser, setUserInSession, /*setAuth0User,*/ update, updatePatch, updateWithFile, setAuthorization
};

