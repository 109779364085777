import { message, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { convertDecimal128 } from "../../../utils/convertNumbers";
import { getQuery } from "../../../services/apiService";

const CurrencyPairsTab = ({assetId}) => {
	const [ pairs, setPairs ] = useState([]);

	useEffect(() => {
		const fetchPairs = async () => {
		  try{
			console.log(assetId);
			const response = await getQuery('/api/v1/trading-assets/get-pairs', { assetId });
	
			if(response.status == 200){
				console.log(response.data.data);
				setPairs(response.data.data);
			}
		  }catch(error){
			message.error('Error while fetching client profile.');
		  }
		}
	
		fetchPairs();
	}, [assetId]);

	/*const columns = [
		{
			title: 'Pair',
			dataIndex: 'pair',
			key: 'pair',
			render: (text) => text.toUpperCase(),
		},
		{
			title: 'Sell Fee',
			dataIndex: 'sellFee',
			key: 'sellFee',
			render: (text) => convertDecimal128(text),
		},
		{
			title: 'Buy Fee',
			dataIndex: 'buyFee',
			key: 'sellFee',
			render: (text) => convertDecimal128(text),
		},
		{
			title: 'Rate API',
			dataIndex: 'rateApi',
			key: 'rateApi',
			render: (rateApi) => (
				<Tag color={rateApi === 'valr' ? 'blue' : 'green'}>
					{rateApi.toUpperCase()}
				</Tag>
			),
		},
		{
			title: 'Base Currency',
			dataIndex: ["baseCurrency", "asset"],
			key: 'baseCurrency',
			render: (currency) => (
				currency.toUpperCase()
			),
		},
		{
			title: 'Quote Currency',
			dataIndex: ["quoteCurrency", "asset"],
			key: 'quoteCurrency',
			render: (currency) => (
				currency.toUpperCase()
			),
		},
		{
			title: 'Status',
			dataIndex: 'enabled',
			key: 'enabled',
			render: (enabled) => (
				<Tag color={enabled ? 'green' : 'red'}>
					{enabled ? 'Enabled' : 'Disabled'}
				</Tag>
			),
		},
	];*/
	const columns = [
		{
		  title: 'Pair',
		  dataIndex: 'pair',
		  key: 'pair',
		  render: (text) => (text ? text.toUpperCase() : 'N/A'),
		},
		{
		  title: 'Sell Fee',
		  dataIndex: 'sellFee',
		  key: 'sellFee',
		  render: (text) => (text !== undefined ? convertDecimal128(text) : 'N/A'),
		},
		{
		  title: 'Buy Fee',
		  dataIndex: 'buyFee',
		  key: 'buyFee',
		  render: (text) => (text !== undefined ? convertDecimal128(text) : 'N/A'),
		},
		{
		  title: 'Rate API',
		  dataIndex: 'rateApi',
		  key: 'rateApi',
		  render: (rateApi) => (
			<Tag color={rateApi === 'valr' ? 'blue' : 'green'}>
			  {rateApi ? rateApi.toUpperCase() : 'Unknown'}
			</Tag>
		  ),
		},
		{
		  title: 'Base Currency',
		  dataIndex: ['baseCurrency', 'asset'],
		  key: 'baseCurrency',
		  render: (currency) => (currency ? currency.toUpperCase() : 'N/A'),
		},
		{
		  title: 'Quote Currency',
		  dataIndex: ['quoteCurrency', 'asset'],
		  key: 'quoteCurrency',
		  render: (currency) => (currency ? currency.toUpperCase() : 'N/A'),
		},
		{
		  title: 'Status',
		  dataIndex: 'enabled',
		  key: 'enabled',
		  render: (enabled) => (
			<Tag color={enabled ? 'green' : 'red'}>
			  {enabled !== undefined ? (enabled ? 'Enabled' : 'Disabled') : 'N/A'}
			</Tag>
		  ),
		},
	];
	  
	return(
		<>
			<Table dataSource={pairs} columns={columns} />
		</>
	)
}

export default CurrencyPairsTab;