import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//import apiService from "../services/apiService";

// components
//import Table from "../../../../components/Table";
//import TableComponent from "../components/TableComponent";
import { getClients } from "../../services/clientService";
//import AdminLayout from "../layouts/AdminLayout";
import PaginatedTable from "../../components/PaginatedTable";
//import AddClientPage from "./AddClientPage";
import AdminLayout from "../../layouts/AdminLayout";
import { formatDate } from "../../utils/formateData";
import { Breadcrumb, Space } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SafetyOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import View from "./View";
import Edit from "./Edit";
import Delete from "./Delete";

/*const columns = [
    {   title: "User Code", 
        dataIndex: "userinfo.ref_info.user_code",
        key: "user_code",
        render: (text, record) => record.userinfo?.ref_info?.user_code || "N/A"
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "First Name",
      dataIndex: ["userinfo personal_info legal_name"],
      key: "firstname",
      render: (text, record) => record.userinfo?.personal_info?.legal_name || "N/A"
    },
    {
      title: "Last Name",
      dataIndex: "userinfo.personal_info.legal_lastname",
      key: "lastname",
      render: (text, record) => record.userinfo?.personal_info?.legal_lastname || "N/A"
    },
    {
      title: "Identity Number",
      dataIndex: "userinfo.personal_info.identity_number",
      key: "identity_number",
      render: (text, record) => record.userinfo?.personal_info?.identity_number || "N/A"
    },
    { 
        title: "Status",
        dataIndex: "status",
        key: "status",
        //render: (text, record) => record.userinfo?.personal_info?.identity_number || "N/A"
    },
    {
      title: "Phone Number",
      dataIndex: "userinfo.personal_info.phoneNumber",
      key: "phone_number",
      render: (text, record) => record.userinfo?.personal_info?.phoneNumber || "N/A"
    },
    {
      title: "Broker Code",
      dataIndex: "userinfo.broker_info.broker_code", // Ensure dataIndex is correct
      key: "broker_code",
      render: (text, record) => record.userinfo?.broker_info?.broker_code || "N/A"
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text), // Updated render function for date formatting
    },
    {
        title: "Actions",
        key: "actions",
        render: (text, record) => (
            <Space size="middle">
                <View userId={record._id} />
                <Edit userId={record._id} />
                <Delete userId={record._id} />
            </Space>
        ),
    }
    // All other columns can be added here similarly without leaving placeholders
];
/*const columns = [
    {
      title: "User Code",
      dataIndex: ["userinfo", "ref_info", "user_code"], // Using array path to navigate nested data
      key: "user_code",
      render: (text) => text || "N/A", // Safely render data
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "First Name",
      dataIndex: ["userinfo", "personal_info", "legal_name"], // Nested data path
      key: "firstname",
      render: (text) => text || "N/A",
    },
    {
      title: "Last Name",
      dataIndex: ["userinfo", "personal_info", "legal_lastname"], // Nested data path
      key: "lastname",
      render: (text) => text || "N/A",
    },
    {
      title: "Identity Number",
      dataIndex: ["userinfo", "personal_info", "identity_number"], // Nested data path
      key: "identity_number",
      render: (text) => text || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Phone Number",
      dataIndex: ["userinfo", "personal_info", "phoneNumber"], // Nested data path
      key: "phone_number",
      render: (text) => text || "N/A",
    },
    {
      title: "Broker Code",
      dataIndex: ["userinfo", "broker_info", "broker_code"], // Nested data path
      key: "broker_code",
      render: (text) => text || "N/A",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text), // Updated render function for date formatting
    },
  ];*/

/*const filters = [
    { name: "userinfo.ref_info.user_code", label: "User Code", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "firstname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "identity_number", label: "Identity Number", type: "text" },
    { name: "status", label: "Status", type: "dropdown", options: ["enabled", "disabled"] },
    { name: "phone_number", label: "Phone Number", type: "text" },
    { name: "broker_code", label: "Broker Code", type: "text" },
    { name: "createdAt", label: "Created At", type: "date" },
];*/

const ClientOverviewPage = () => {
  /*
   *   modal handling
   */

  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "User Code",
      dataIndex: "userinfo.ref_info.user_code",
      key: "user_code",
      render: (text, record) => record.userinfo?.ref_info?.user_code || "N/A",
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "First Name",
      dataIndex: ["userinfo personal_info legal_name"],
      key: "firstname",
      render: (text, record) =>
        record.userinfo?.personal_info?.legal_name || "N/A",
    },
    {
      title: "Last Name",
      dataIndex: "userinfo.personal_info.legal_lastname",
      key: "lastname",
      render: (text, record) =>
        record.userinfo?.personal_info?.legal_lastname || "N/A",
    },
    {
      title: "Identity Number",
      dataIndex: "userinfo.personal_info.identity_number",
      key: "identity_number",
      render: (text, record) =>
        record.userinfo?.personal_info?.identity_number || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      //render: (text, record) => record.userinfo?.personal_info?.identity_number || "N/A"
    },
    {
      title: "Phone Number",
      dataIndex: "userinfo.personal_info.phoneNumber",
      key: "phone_number",
      render: (text, record) =>
        record.userinfo?.personal_info?.phoneNumber || "N/A",
    },
    {
      title: "Broker Code",
      dataIndex: "userinfo.broker_info.broker_code", // Ensure dataIndex is correct
      key: "broker_code",
      render: (text, record) =>
        record.userinfo?.broker_info?.broker_code || "N/A",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatDate(text), // Updated render function for date formatting
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <View userId={record._id} />
          <Edit userId={record._id} onUpdateSuccess={setRefresh} />
          <Delete userId={record._id} onDeleteSuccess={setRefresh} />
        </Space>
      ),
    },
    // All other columns can be added here similarly without leaving placeholders
  ];

  const filters = [
    { name: "userinfo.ref_info.user_code", label: "User Code", type: "text" },
    { name: "email", label: "Email", type: "text" },
    { name: "firstname", label: "First Name", type: "text" },
    { name: "lastname", label: "Last Name", type: "text" },
    { name: "identity_number", label: "Identity Number", type: "text" },
    {
      name: "status",
      label: "Status",
      type: "dropdown",
      options: ["enabled", "disabled"],
    },
    { name: "phone_number", label: "Phone Number", type: "text" },
    { name: "broker_code", label: "Broker Code", type: "text" },
    { name: "createdAt", label: "Created At", type: "date" },
  ];

  return (
    <AdminLayout
      user={null}
      header={"Client Overview"}
      currentKey={"sub1"}
      openKey="4"
    >
      <div className="mx-auto p-4">
        <Breadcrumb className="mb-4">
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Clients</Breadcrumb.Item>
          <Breadcrumb.Item>Overview</Breadcrumb.Item>
        </Breadcrumb>
        <div className="mb-4">
          <h3 className="text-lg font-semibold">Client Overview</h3>
        </div>

        <PaginatedTable
          fetchEndpoint="/api/v1/clients/index"
          columns={columns}
          //actionColumn={actionColumn}
          isSearchable={true}
          isSelectable={true}
          filters={filters}
          refresh={refresh}
        />
      </div>
    </AdminLayout>
  );
};

export default ClientOverviewPage;
