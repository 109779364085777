import React, { useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

const TradingAssetChainForm = ({ onSubmit, onPrevious, previousData }) => {
  const [form] = Form.useForm();
  const [chains, setChains] = useState([{ key: Date.now() }]); // Initialize with one row

  const handleAddRow = () => {
    setChains([...chains, { key: Date.now() }]);
  };

  const handleRemoveRow = (key) => {
    setChains(chains.filter((chain) => chain.key !== key));
  };

  const onFinish = (values) => {
    const chainsData = values.chains || [];
    console.log("Step 2 data:", { chains: chainsData, ...previousData });
    onSubmit({ chains: chainsData, ...previousData });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="p-4"
      initialValues={{ chains: chains.map(() => ({})) }}
    >
      <h2 className="text-xl font-bold mb-4">Trading Asset Chain Form</h2>

      {chains.map((chain, index) => (
        <Row gutter={16} key={chain.key} className="mb-2" align="middle">
          <Col span={5}>
            <Form.Item
              label="Asset ID"
              name={["chains", index, "assetId"]}
              rules={[{ required: false, message: "Asset ID is required" }]}
            >
              <Input
                placeholder="Enter Asset ID"
                defaultValue={previousData.asset}
                readOnly
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              label="Chain"
              name={["chains", index, "chain"]}
              rules={[{ required: true, message: "Chain is required" }]}
            >
              <Input placeholder="Enter Chain" />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="Fireblocks Test" name={["chains", index, "fireblocksTest"]}>
              <Input placeholder="Enter Fireblocks Test" />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              label="Fireblocks Live"
              name={["chains", index, "fireblocksLive"]}
              rules={[{ required: true, message: "Fireblocks Live is required" }]}
            >
              <Input placeholder="Enter Fireblocks Live" />
            </Form.Item>
          </Col>

          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            {chains.length > 1 && (
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveRow(chain.key)}
                style={{ marginTop: 8 }}
              />
            )}
          </Col>
        </Row>
      ))}

      <Button
        type="dashed"
        onClick={handleAddRow}
        className="mb-4"
        icon={<PlusOutlined />}
      >
        Add Chain
      </Button>

      <div className="flex justify-between mt-4">
        {/* Previous Button */}
        <Button type="default" onClick={onPrevious}>
          Previous
        </Button>

        {/* Submit Button */}
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default TradingAssetChainForm;
