import React, { useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
// import { message } from "antd";
import PaginatedTable from "../../components/PaginatedTable";
import { useDeleteNotificationService } from "../../services/notificationService";
import DeleteNotificationModal from "../../Modals/DeleteNotificationModal";
import UpdateNotificationModal from "../../Modals/UpdateNotificationModal";
import CreateNotificationModal from "../../Modals/CreateNotification";

const NotificationIndex = () => {
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { deleteNotification } = useDeleteNotificationService();

  const columns = [
    {
      title: "Heading",
      dataIndex: "heading",
      key: "heading",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <EditOutlined
            className="cursor-pointer mx-2"
            onClick={() => handleUpdateNotification(record)}
          />
          <DeleteOutlined
            className="cursor-pointer mx-2"
            onClick={() => handleDeleteNotification(record)}
          />
        </>
      ),
    },
  ];

  const filters = [
    {
      name: "heading",
      label: "Heading",
      type: "text",
    },
    {
      name: "message",
      label: "Message",
      type: "text",
    },
  ];

  const handleCreateNotification = () => {
    setIsCreateModalVisible(true);
  };

  const handleUpdateNotification = (notification) => {
    setCurrentNotification(notification);
    setIsUpdateModalVisible(true);
  };

  const handleDeleteNotification = (notification) => {
    setCurrentNotification(notification);
    setIsDeleteModalVisible(true);
  };

  const handleCreateSubmit = () => {
    setIsCreateModalVisible(false);
  };

  return (
    <AdminLayout currentKey="sub1" openKey="4">
      <div className="p-6 bg-white">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-semibold">Notifications</h1>
          <div className="text-xs text-gray-500">
            Dashboard &gt; Notifications
          </div>
        </div>

        <div className="flex justify-between items-center mb-6">
          <button
            className="flex items-center px-4 rounded-lg py-2 bg-[#22c6ab] text-white hover:bg-[#1da089] transition"
            onClick={handleCreateNotification}
          >
            <PlusOutlined className="mr-2" /> Create New Notification
          </button>
        </div>

        <PaginatedTable
          fetchEndpoint="/api/v1/pushnotifications/all"
          columns={columns}
          filters={filters}
        />

        {/* Update Notification Modal */}
        <UpdateNotificationModal
          visible={isUpdateModalVisible}
          onCancel={() => setIsUpdateModalVisible(false)}
          onSubmit={handleCreateSubmit}
          notificationData={currentNotification}
        />

        {/* Create Notification Modal */}
        <CreateNotificationModal
          visible={isCreateModalVisible}
          onCancel={() => setIsCreateModalVisible(false)}
          onSubmit={handleCreateSubmit}
        />

        {/* Delete Notification Modal */}
        <DeleteNotificationModal
          visible={isDeleteModalVisible}
          onCancel={() => setIsDeleteModalVisible(false)}
          onDelete={async (notificationId) => {
            try {
              await deleteNotification(notificationId, () => {
                setIsDeleteModalVisible(false);
              });
            } catch (err) {}
          }}
          notificationId={currentNotification?._id}
          notificationHeading={currentNotification?.heading}
        />
      </div>
    </AdminLayout>
  );
};

export default NotificationIndex;
