import { useQueryClient } from "@tanstack/react-query";
import { message } from 'antd';
import { useApiMutation, useApiQuery } from "../hooks/useApiRequest";

//* ---------Get all crypto deposits---------
export const useGetAllCryptoDeposits = () => {
    return useApiQuery({
        key: ["user-crypto-deposits"],
        endPoint: `/api/v1/crypto-transactions`,
        method: "GET",
    });
};



//*********** add user Crypto Deposit**********
export const useAddUserCryptoDepositService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const addUserCryptoDeposit = async (values, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-deposits-add"],
                endPoint: `/api/v1/crypto-transactions/create`,
                method: "POST",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-deposits-add"] });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-deposits"] });
            onSuccess();
            message.success('Successfully added');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { addUserCryptoDeposit, error };
};




//*********** Update user Crypto deposit**********
export const useUpdateUserCryptoDepositStatusService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const updateUserCryptoDeposit = async (values, id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-deposits-update-status", id],
                endPoint: `/api/v1/crypto-transactions/${id}`,
                method: "PATCH",
                bodyContent: values,
            });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-deposits-update-status"] });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-deposits", id] });
            onSuccess();
            message.success('Successfully updated');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { updateUserCryptoDeposit, error };
};



//*********** Delete user crypto deposit **********
export const useDeleteUserCryptoDepositService = () => {
    const queryClient = useQueryClient();
    const { mutateAsync, error } = useApiMutation();

    const deleteDepositCrypto = async (id, onSuccess, onError) => {
        try {
            await mutateAsync({
                key: ["user-crypto-deposits-delete"],
                endPoint: `/api/v1/crypto-transactions/${id}`,
                method: "DELETE",
            });
            queryClient.invalidateQueries({ queryKey: ["user-crypto-deposits-delete"] });
            onSuccess();
            message.success('Successfully deleted');
        } catch (error) {
            onError();
            message.error(error.message);
        }
    };

    return { deleteDepositCrypto, error };
};


















