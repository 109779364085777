// Modals/ViewUserModal.jsx

import React, { useState, useEffect } from 'react';
import { Modal, Tabs, Descriptions, Table, Spin, message, Button, Space } from 'antd';
import axios from 'axios';
import {
  UserOutlined,
  DollarOutlined,
  BankOutlined,
  MoneyCollectOutlined,
  ExportOutlined,
  KeyOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';

//import EditPageModal from './EditPageModal';
import ProfileInfoTab from './Tabs/ProfileInfoTab';
import BalanceOveriewTab from './Tabs/BalanceOverviewTab';
import BankAccountsTab from './Tabs/BankAccountsTab';
import WalletsTab from './Tabs/WalletsTab';

const { TabPane } = Tabs;

const View = ({ /*visible,* onCancel, userData*/ userId }) => {
  const [activeTab, setActiveTab] = useState('1');
  const [balanceData, setBalanceData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  //const [currentUserData, setCurrentUserData] = useState(userData);
  const [visible, setVisible] = useState(false);

  
  const toggleView = () => {
    setVisible(!visible);
  };

  if (!visible) {
    return (
      <Button
          type="text"
          icon={<EyeOutlined />}
          style={{
              padding: 0, // Removes padding for a cleaner look
              display: "flex", // Ensures proper alignment
              alignItems: "center",
              justifyContent: "center",
          }}
          onClick={toggleView} // Properly placed onClick handler
      />
    );
  }

  return (
    <Modal
      title="View User"
      open={visible}
      onCancel={toggleView}
      footer={null}
      width={1200}
      style={{ maxHeight: '80vh', overflowY: 'auto', background: '#FFFFFF' }}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        {/* Profile Information Tab */}
        <TabPane
          tab={
            <span>
              <UserOutlined /> Profile Information
            </span>
          }
          key="1"
        >
          <ProfileInfoTab userID={userId} />
        </TabPane>

        {/* Balance Overview Tab */}
        <TabPane
          tab={
            <span>
              <DollarOutlined /> Balance Overview
            </span>
          }
          key="2"
        >
          {/*{loading ? (
            <Spin />
          ) : balanceData.length > 0 ? (
            <BalanceOveriewTab activeTab={activeTab} />
          ) : (
            <p>No balance data available.</p>
          )}*/}
          {<BalanceOveriewTab activeTab={activeTab} userID={userId} />}
        </TabPane>

        {/* Additional Tabs */}
        <TabPane
          tab={
            <span>
              <BankOutlined /> Bank Account(s)
            </span>
          }
          key="3"
        >
          {/* Bank Account(s) content */}
          <BankAccountsTab activeTab={activeTab} userID={userId} />
        </TabPane>
        {/*<TabPane
          tab={
            <span>
              <MoneyCollectOutlined /> USD Bank Accounts
            </span>
          }
          key="4"
        >
          {/* USD Bank Accounts content goes here }
        </TabPane>*/}
        <TabPane
          tab={
            <span>
              <ExportOutlined /> Withdrawal Addresses
            </span>
          }
          key="5"
        >
          {/* Withdrawal Addresses content */}
          <WalletsTab activeTab={activeTab} userID={userId} />
        </TabPane>
        <TabPane
          tab={
            <span>
              <KeyOutlined /> Client API Keys
            </span>
          }
          key="6"
        >
          {/* Client API Keys content  - there */}
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default View;
