// src/routes/PrivateRoute.jsx

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
//import APIService from "../services/apiService";

/**
 * PrivateRoute forces the authorization before the route can be accessed
 * @returns {HTMLElement} - A route element or redirects
 */
const PrivateRoute = ({children, roles }) => {
    // Check if the user is authenticated
    /*if (!APIService.isUserAuthenticated()) {
        // Not logged in, so redirect to the login page with the return URL
        return <Navigate to="/auth/login" />;
    }

    const loggedInUser = APIService.getLoggedInUser();

    // Check if the route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // Role not authorized, redirect to the home page
        return <Navigate to="/" />;
    }*/

    // Authorized, render the children components
	console.log("User is authorized. Rendering children.");
    return children;//<Outlet />;
};

export default PrivateRoute;
