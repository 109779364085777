import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber, Select, Button, Upload, Switch, message } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the Quill stylesheet
import { getQuery } from "../services/apiService";
import { convertDecimal128 } from "../utils/convertNumbers";

const { Option } = Select;

const TradingAssetForm = ({ onNextStep, previousData, assetId  }) => {
  const [form] = Form.useForm();
  const [asset, setAsset] = useState({});

  // Set form fields with previous data on mount
  useEffect(() => {
    if (previousData) {
      form.setFieldsValue(previousData);
      return;
    }

    const fetchClientData = async () => {
      try {
        const response = await getQuery('/api/v1/trading-assets/get', {id: assetId});
          if (response?.data) {
              console.log(response.data.data);
              const formValues = mapApiDataToFormFields(response.data.data);
              setAsset(response.data.data);
              form.setFieldsValue(formValues); // Dynamically update the form with fetched data
          }
      } catch (error) {
          message.error("Failed to fetch asset data");
      }
    };

    if(assetId){
      fetchClientData();
    }
  }, [previousData, form, assetId]);

  const onFinish = (values) => {
    console.log("Step 1 data:", values);
    onNextStep(values); // Pass data to the next step
  };

  const beforeUpload = (file) => {
    message.success(`${file.name} file uploaded successfully`);
    return false; // Prevent automatic upload, rely on form submission
  };

  const mapApiDataToFormFields = (apiData) => ({
    asset: apiData?.asset || "",
    assetName: apiData?.assetName || "",
    notes: apiData?.notes || "",
    minAmount: convertDecimal128(apiData?.minAmount) || 0,
    confirmations: apiData?.confirmations || 0,
    payAddress: apiData?.payAddress || "",
    fees: convertDecimal128(apiData?.fees) || 0,
    withdrawalNotes: apiData?.withdrawalNotes || "",
    withdrawalFees: convertDecimal128(apiData?.withdrawalFees) || 0,
    withdrawalMinAmount: convertDecimal128(apiData?.withdrawalMinAmount) || 0,
    type: apiData?.type || "",
    image: apiData?.image || "",
    "serviceFee.feeType": apiData?.serviceFee?.feeType || "",
    "serviceFee.feeAmount": convertDecimal128(apiData?.serviceFee?.feeAmount) || 0,
    status: apiData?.status || "",
    reason: apiData?.reason || "",
    liqBuyStatus: apiData?.liqBuyStatus || false,
    liqBuySpread: convertDecimal128(apiData?.liqBuySpread) || 0,
    liqSellStatus: apiData?.liqSellStatus || false,
    liqSellSpread: convertDecimal128(apiData?.liqSellSpread) || 0,
  });
  

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="p-4"
    >
      <h2 className="text-xl font-bold mb-4">Trading Asset Form</h2>

      {/* Asset */}
      <Form.Item
        label="Asset"
        name="asset"
        rules={[{ required: true, message: "Asset is required" }]}
      >
        <Input />
      </Form.Item>

      {/* Asset Name */}
      <Form.Item
        label="Asset Name"
        name="assetName"
        rules={[{ required: true, message: "Asset Name is required" }]}
      >
        <Input />
      </Form.Item>

      {/* Notes */}
      <Form.Item label="Deposit Notes" name="notes" initialValue={asset?.notes || ""}>
        <div style={{ border: "1px solid #d9d9d9", borderRadius: "4px", padding: "4px" }}>
          <ReactQuill
            theme="snow"
            onChange={(value) => form.setFieldValue("notes", value)}
            placeholder="Enter notes here"
            style={{ height: "200px", overflow: "hidden" }} // Ensure consistent height
            defaultValue={asset?.notes || ""}
          />
        </div>
      </Form.Item>

      {/* Minimum Amount */}
      <Form.Item
        label="Minimum Deposit Amount"
        name="minAmount"
        rules={[{ required: true, message: "Minimum Amount is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Confirmations */}
      <Form.Item
        label="Deposit Confirmations Amount"
        name="confirmations"
        rules={[{ required: true, message: "Confirmations are required" }]}
      >
        <InputNumber min={1} />
      </Form.Item>

      {/* Pay Address */}
      <Form.Item label="Pay Address" name="payAddress">
        <Input />
      </Form.Item>

      {/* Fees */}
      <Form.Item
        label="Deposit Fee"
        name="fees"
        rules={[{ required: true, message: "Fees are required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Withdrawal Notes */}
      <Form.Item
        label="Withdrawal Notes"
        name="withdrawalNotes"
        rules={[{ required: true, message: "Withdrawal Notes are required" }]}
        initialValue={asset?.withdrawalNotes || ""}
      >
        <div style={{ border: "1px solid #d9d9d9", borderRadius: "4px", padding: "4px" }}>
          <ReactQuill
            theme="snow"
            onChange={(value) => form.setFieldValue("withdrawalNotes", value)}
            placeholder="Enter notes here"
            style={{ height: "200px", overflow: "hidden" }} // Ensure consistent height
            defaultValue={asset?.withdrawalNotes || ""}
          />
        </div>
      </Form.Item>

      {/* Withdrawal Fees */}
      <Form.Item
        label="Withdrawal Fees"
        name="withdrawalFees"
        rules={[{ required: true, message: "Withdrawal Fees are required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Withdrawal Minimum Amount */}
      <Form.Item
        label="Withdrawal Minimum Amount"
        name="withdrawalMinAmount"
        rules={[{ required: true, message: "Withdrawal Minimum Amount is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Asset Type */}
      <Form.Item
        label="Asset Type"
        name="type"
        rules={[{ required: true, message: "Asset Type is required" }]}
      >
        <Select>
          <Option value="Crypto">Crypto</Option>
          <Option value="Fiat">Fiat</Option>
        </Select>
      </Form.Item>

      {/* Image */}
      <Form.Item
        label="Image"
        name="image"
        rules={[{ required: true, message: "Image is required" }]}
      >
        <Upload beforeUpload={beforeUpload} multiple={false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>

      {/* Service Fee */}
      <Form.Item
        label="Service Fee Type"
        name="serviceFee.feeType"
        rules={[{ required: true, message: "Service Fee Type is required" }]}
      >
        <Select>
          <Option value="percentage">Percentage</Option>
          <Option value="fixed">Fixed</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Service Fee Amount"
        name="serviceFee.feeAmount"
        rules={[{ required: true, message: "Service Fee Amount is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Status */}
      <Form.Item
        label="Status"
        name="status"
        rules={[{ required: true, message: "Status is required" }]}
      >
        <Select>
          <Option value="Enabled">Enabled</Option>
          <Option value="Disabled">Disabled</Option>
        </Select>
      </Form.Item>

      {/* Reason */}
      <Form.Item label="Reason" name="reason">
        <Input.TextArea />
      </Form.Item>

      {/* Liquidity Buy Status */}
      <Form.Item
        label="Liquidity Buy Status"
        name="liqBuyStatus"
        valuePropName="checked"
        rules={[{ required: true, message: "Liquidity Buy Status is required" }]}
      >
        <Switch />
      </Form.Item>

      {/* Liquidity Buy Spread */}
      <Form.Item
        label="Liquidity Buy Spread"
        name="liqBuySpread"
        rules={[{ required: true, message: "Liquidity Buy Spread is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      {/* Liquidity Sell Status */}
      <Form.Item
        label="Liquidity Sell Status"
        name="liqSellStatus"
        valuePropName="checked"
        rules={[{ required: true, message: "Liquidity Sell Status is required" }]}
      >
        <Switch />
      </Form.Item>

      {/* Liquidity Sell Spread */}
      <Form.Item
        label="Liquidity Sell Spread"
        name="liqSellSpread"
        rules={[{ required: true, message: "Liquidity Sell Spread is required" }]}
      >
        <InputNumber min={0} />
      </Form.Item>

      <Button type="primary" htmlType="submit" className="mt-4">
        Next
      </Button>
    </Form>
  );
};

export default TradingAssetForm;
