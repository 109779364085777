import React, { useState } from "react";
import { Modal, Button, Space, Steps, message } from "antd";
import TradingAssetForm from "../../models/TradingAsset";
import TradingAssetChainForm from "../../models/TradingAssetChain";
import { PlusOutlined } from "@ant-design/icons";
import { create, createWithFile } from "../../services/apiService";
import InvestmentProductForm from "../../models/InvestmentProduct";
import InvestmentTermsAndConditionsForm from "../../models/InvestmentTerms";
import FAQForm from "../../models/FAQ";

const { Step } = Steps;

const Create = ({onCreateSuccess}) => {
  const [visible, setVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [formData, setFormData] = useState({}); // Store form data

  const toggleForm = () => {
    setVisible(!visible);
  };

  const handleNextStep = (data) => {
    setFormData((prevData) => ({
      ...prevData,
      ...data,
    }));
    setCurrentStep(currentStep + 1); // Move to next step
  };

  const onPrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1); // Go back to previous step
    }
  };

  /*const handleFinalSubmit = async (data) => {
    try{
        console.log(data);
        //return;
        const finalData = { ...formData, ...data };
        console.log('Final submitted data:', finalData);
        const response = await create('/api/v1/investment-product/create', finalData);

        if(response.status == 201 && response.data.data){
            message.success('Trading asset created successfully');
            onCreateSuccess(Math.random() > 0.5);
            toggleForm();
        }
    }catch(error){
      message.error('Could not create new asset')
    }
  };*/
    const handleFinalSubmit = async (data) => {
        try {
            console.log("Form Data:", data);

            // Create a new FormData instance to handle the file upload along with other form data
            const formData = new FormData();

            // Append other form fields to FormData
            for (const key in data) {
                if (data[key] && key !== 'images') { // Skip images for now as we handle them separately
                    formData.append(key, data[key]);
                }
            }

            // Handle the file uploads from the images field
            if (data.images && data.images.fileList) {
                data.images.fileList.forEach((file) => {
                    formData.append('images', file.originFileObj);  // Add each file to FormData
                });
            }

            console.log("Final submitted data with files:", formData);

            // Send the FormData to your API using the create function (adjust as needed)
            /*const response = await create('/api/v1/investment-product/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set content type to multipart for file upload
                }
            });*/
            const response = await createWithFile('/api/v1/investment-product/create', data);

            if (response.status === 201 && response.data?.data) {
                message.success('Trading asset created successfully');
                onCreateSuccess(Math.random() > 0.5);  // Example success handler
                toggleForm();  // Close or reset the form
            } else {
                message.error('Failed to create trading asset. Please try again.');
            }
        } catch (error) {
            console.error('Submission Error:', error);
            message.error(error.response?.data?.message || 'Could not create new asset');
        } finally {
            // Optional: Reset form fields after submission (if necessary)
            // form.resetFields(); // Uncomment if you want to reset form fields after submission
        }
    };


  const stepsData = [
    { title: "Step 1: Product Details" },
    { title: "Step 2: Trading Asset Chain" },
    { title: "Step 2: QA" },
  ];

  if (!visible) {
    return (
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Investments</h1>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={toggleForm}
            style={{ backgroundColor: '#22c6ab', borderColor: '#22c6ab' }}
          >
            Create Investment
          </Button>
        </Space>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <Modal
        title="Create Investment Product"
        open={visible}
        onCancel={toggleForm}
        footer={null}
        width={1200}
        destroyOnClose
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        {/* Steps component for showing progress */}
        <Steps current={currentStep - 1} size="small" className="mb-6">
          {stepsData.map((step, index) => (
            <Step key={index} title={step.title} />
          ))}
        </Steps>

        {/* Render forms based on the current step */}
        {currentStep === 1 && (
        
          <InvestmentProductForm
            onNextStep={handleNextStep}
            previousData={formData}
          />
        )}
        
        {currentStep === 2 && (
          <InvestmentTermsAndConditionsForm
            onNextStep={handleNextStep}
            onPrevious={onPrevious}
            initialData={formData}
          />
        )}
        {currentStep === 3 && (
          <FAQForm
            onSubmit={handleFinalSubmit}
            onPrevious={onPrevious}
            previousData={formData}
          />
        )}
      </Modal>
    </div>
  );
};

export default Create;
