import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PaginatedTable from '../../components/PaginatedTable';


const TradingFees = () => {
  const [feesData, setFeesData] = useState([]);

  // Fetch trading fees data on component mount
  useEffect(() => {
    const fetchFeesData = async () => {
      try {
        const response = await axios.get('http://localhost:3000/api/v1/trading-fees');
        setFeesData(response.data.data);
      } catch (error) {
        console.error('Error fetching trading fees:', error);
      }
    };

    fetchFeesData();
  }, []);

  // Define table columns
  const columns = [
    {
      title: 'Asset From',
      dataIndex: 'assetFrom',
      key: 'assetFrom',
    },
    {
      title: 'Asset To',
      dataIndex: 'assetTo',
      key: 'assetTo',
    },
    {
      title: 'Fee (%)',
      dataIndex: 'feePercentage',
      key: 'feePercentage',
      render: (feePercentage) => `${feePercentage.toFixed(2)}%`, // Format to 2 decimal places
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h2>Trading Fees</h2>
      <PaginatedTable
        columns={columns}
        dataSource={feesData}
        itemsPerPage={10}
        onView={(record) => console.log('View:', record)}
        onEdit={(record) => console.log('Edit:', record)}
        onDelete={(record) => console.log('Delete:', record)}
      />
    </div>
  );
};

export default TradingFees;

