import React from 'react';

const TwoFactorAuth = () => {
    const loginHistory = [
        { platform: "Windows, Chrome", ip: "165.165.238.10", date: "2024-11-07 11:44:47" },
        { platform: "Windows, Chrome", ip: "165.165.238.10", date: "2024-11-07 09:16:27" },
        { platform: "Windows, Chrome", ip: "165.165.238.10", date: "2024-11-06 15:48:59" },
        { platform: "Windows, Chrome", ip: "165.165.238.10", date: "2024-11-06 12:45:20" },
        { platform: "Windows, Chrome", ip: "165.165.238.10", date: "2024-11-05 14:54:53" }
    ];

    return (
        <div className="bg-white p-8">
            <div className="max-w-4xl mx-auto">
                {/* Password Section */}
                <div className="bg-white p-6 rounded-md shadow-md mb-4">
                    <h2 className="font-semibold mb-2">Password</h2>
                    <div className="border-b border-gray-200 mb-4">
                        <p>Password: **********</p>
                    </div>
                    <button className="bg-purple-700 hover:bg-purple-800 text-white font-semibold py-2 px-4 rounded">
                        RESET PASSWORD
                    </button>
                </div>

                {/* 2FA Section */}
                <div className="bg-white p-6 rounded-md shadow-md mb-4">
                    <h2 className="font-semibold mb-2">2FA</h2>
                    <p>Setup two-factor authentication as an extra layer of security for your account.</p>
                    <button className="mt-4 bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded">
                        ENABLE 2FA
                    </button>
                </div>

                {/* Login History Section */}
                <div className="bg-white p-6 rounded-md shadow-md">
                    <h2 className="font-semibold mb-2">Login History</h2>
                    <table className="min-w-full leading-normal">
                        <thead>
                            <tr>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Platform
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    IP Address
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                    Date/Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {loginHistory.map((entry, index) => (
                                <tr key={index}>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        {entry.platform}
                                    </td>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        {entry.ip}
                                    </td>
                                    <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                        {entry.date}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default TwoFactorAuth;
