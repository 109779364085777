import { SyncOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import DetailView from "../../../components/DetailView";
import { getQuery } from "../../../services/apiService";
import { useAddToMondayService } from '../../../services/clientService';
//import DetailView from "../../components/DetailView";

const ProfileInfoTab = ({ userID, visible, onCancel }) => {

  const [profile, setProfile] = useState({});
  const [isLoading, setLoading] = useState(false);
  const { addUserToMonday } = useAddToMondayService()

  useEffect(() => {
    const fetchProfileInfo = async () => {
      try {
        const response = await getQuery('/api/v1/clients/view', { clientId: userID });

        if (response.status == 200) {
          setProfile(response.data.data);
        }
      } catch (error) {
        message.error('Error while fetching client profile.');
      }
    }

    fetchProfileInfo();
  }, [userID]);

  const handleFormSubmit = async () => {
    setLoading(true)
    addUserToMonday(
      userID,
      () => setLoading(false),
      () => setLoading(false)
    )
  };

  const profileInfo = [
    { label: 'Account Type', value: profile?.accountType || 'N/A' },
    { label: 'User Code', value: profile?.ref_info?.user_code || 'N/A' },
    { label: 'Email', value: profile?.email || 'N/A' },
    { label: 'First Name', value: profile?.personal_info?.name || 'N/A' },
    { label: 'Last Name', value: profile?.personal_info?.lastname || 'N/A' },
    { label: 'Legal First Name', value: profile?.personal_info?.legal_name || 'N/A' },
    { label: 'Legal Last Name', value: profile?.personal_info?.legal_lastname || 'N/A' },
    { label: 'Identity Number', value: profile?.personal_info?.identity_number || 'N/A' },
    { label: 'Phone Number', value: profile?.personal_info?.phoneNumber || 'N/A' },
    //{ label: 'Broker Code', value: convertDecimal128(data.withdrawlFees) },
    { label: 'City', value: profile?.address?.city || 'N/A' },
    { label: 'Address Line 1', value: profile.type },
    { label: 'Street', value: profile?.address?.street || 'N/A' },
    { label: 'Suburb', value: profile?.address?.suburb || 'N/A' },
    { label: "State/Province", value: profile?.address?.province || 'N/A' },
    { label: "Poastal / Zip Code", value: profile?.address?.postal_code || 'N/A' },
    { label: 'Country', value: profile?.address?.country || 'N/A' },
    { label: 'Date of Birth', value: profile?.personal_info?.dob || 'N/A' },
    { label: 'Source of Funds', value: profile?.funds_source || 'N/A' },
    { label: 'SARS TAX Number', value: profile?.company_info?.sars_no },
    { label: "Referral Code", value: profile?.ref_info?.refeferal_code || 'N/A' },
    /*{ label: "ID / Passport Documents", value: data.liqBuyStatus < 1 ? 'Off' : 'On' },
    { label: "ID / Passport Documents - Back", value: convertDecimal128(data.liqBuySpread) },
    { label: "Proof of Address", value: data.liqSellStatus < 1 ? 'Off' : 'On' },
    { label: 'Broker Assigned Code', value: data?.broker_info?.broker_code || 'N/A'},
    { label: "Spread OTC", value: data.reason },
    { label: "Spread Arbitrage", value: data.liqBuyStatus < 1 ? 'Off' : 'On' },*/

  ];

  return (
    <>
      <div className="flex justify-end p-4">
        <Button
          type="primary"
          icon={isLoading ? <SyncOutlined /> : <UploadOutlined />}
          style={{
            padding: '10px 20px', // Removes padding for a cleaner look
            display: "flex", // Ensures proper alignment
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={handleFormSubmit} // Properly placed onClick handler
        >
          {isLoading ? "Loading..." : "Upload To monday.com"}
        </Button>
      </div>
      <DetailView data={profileInfo} className="w-full" />


    </>
  );
};

export default ProfileInfoTab;
