import { axiosInstance } from "../services/apiService";

export const fetcher = async (url, method = "GET", requestData) => {
    try {
        const config = {
            method,
            url,
            ...(requestData && { data: requestData }),
        };

        const response = await axiosInstance(config);

        if (response.data && response.data.status) {
            return response.data.data;
        }
    } catch (error) {

        console.log(" THE ENTIRE ERROR : ", error);
        console.log("  ERROR MESSAGE: ", error?.response?.data?.message);

        if (error.response.data.message) {
            throw new Error(error?.response?.data?.message || "An error occurred");
            // return error
        } else if (error.response.data.error) {
            throw new Error(error?.response?.data?.error || "An error occurred");
        } else {
            throw new Error(error || "An error occurred");
            // return error
        }
    }
};
